import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import FormEdit from './utilities/TriageRequestApplication/form';
import DashboardContent from './utilities/TriageRequestApplication/Dashboard';
import Mail1Content from './utilities/TriageRequestApplication/Mail1Triage';
import Mail2Content from './utilities/TriageRequestApplication/Mail2';
import MicrosoftLogin2 from './utilities/Login/MLogin';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import config from './config/config';
import ContentDashboard from './utilities/TriageTechnicalContentApplication/contentDashboard';
import ContentEdit from './utilities/TriageTechnicalContentApplication/contentEdit';
import TechnicalContentMail from './utilities/TriageTechnicalContentApplication/contentMail';
import BlankMail from './utilities/TriageRequestApplication/blankMail';
import UnauthorizedScreen from './utilities/UnathorizedPage/unauthorized';
import FindSpeaker from './utilities/FindSpeaker/findSpeaker';

function App() {
  const msalConfig = {
    auth: {
      clientId: `${config.micorsoftClientId}`,
      authority: config.authorityUrl
    },
    cache: {
      cacheLocation: 'sessionStorage', // Cache location can be 'localStorage' or 'sessionStorage'
      storeAuthStateInCookie: false, // Set this to true if you want to store the auth state in a cookie
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <div>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Routes>

            <Route exact path="/" element={<MicrosoftLogin2 />} />
            <Route exact path='/home' element={<DashboardContent />} />
            <Route exact path="/triagemail" element={<Mail1Content />} />
            <Route exact path='/mail' element={<Mail2Content />} />
            <Route exact path='/edit' element={<FormEdit />} />
            <Route exact path='/defaultmail' element={<BlankMail />} />

            <Route exact path='/technicalcontenthome' element={<ContentDashboard />} />
            <Route exact path='/technicalcontentedit' element={<ContentEdit />} />
            <Route exact path='/technicalcontentmail' element={<TechnicalContentMail />} />

            <Route exact path='/findSpeaker' element={<FindSpeaker />} />

            <Route exact path='/unathorized' element={<UnauthorizedScreen />} />
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </div>
  );
}

export default App;
