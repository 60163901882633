import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Backdrop, CircularProgress } from '@mui/material';
import { Alert, Avatar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Link, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDropzone } from 'react-dropzone';
import Navbar from '../components/Navbar';
import TimezoneSelect from 'react-timezone-select';
import { Link as MUILink } from '@mui/material';
import Reactselect from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMsal } from '@azure/msal-react';
// import InputAdornment from '@mui/material/InputAdornment';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenubarForm from './menubarForm';
import BaseConfig from '../../config/config'
import MailHistory from './mailHistory';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

const drawerWidth = 420;
const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '40px',
        height: '40px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '45px',
        height: '45px',
    }),


    valueContainer: (provided, state) => ({
        ...provided,
        height: '45px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '45px',
    }),
};

const statuslist = [
    { value: 'Speaker Requested', label: 'Speaker Requested' },
    { value: 'In Triage', label: 'In Triage' },
    { value: 'Speaker Accepted', label: 'Speaker Accepted' },
    { value: 'Speaker Assigned', label: 'Speaker Assigned' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Action Required', label: 'Action Required' },
    { value: 'Request Cancelled', label: 'Request Cancelled' },
    { value: 'Request Unfulfilled', label: 'Request Unfulfilled' },
    { value: 'Meeting Cancelled', label: 'Meeting Cancelled' },
    { value: 'Meeting Rescheduled', label: 'Meeting Rescheduled' },
    { value: 'Speaker Cancelled', label: 'Speaker Cancelled' },
];

const VisibleList = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

const autoEmailList = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

const observableList = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

const opportunityList = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

const styleList = [
    { value: 'Strategic Customer Engagement or Event', label: 'Strategic Customer Engagement or Event' },
    { value: 'Technical Content Request', label: 'Technical Content Request' },
    { value: 'AI Platform Buddy Program', label: 'AI Platform Buddy Program' },
    { value: 'Executive Briefing (EBC)', label: 'Executive Briefing (EBC)' }
];

const meetingFormatList = [
    { value: 'In Person', label: 'In Person' },
    { value: 'Remote', label: 'Remote' },
    { value: 'Hybrid', label: 'Hybrid' },
];

const flexibilityList = [
    { value: 'Specific', label: 'Specific' },
    { value: 'Span', label: 'Span' },
    { value: 'Flexible', label: 'Flexible' },
];

const durationList = [
    { value: 30, label: "30 minutes" },
    { value: 45, label: "45 minutes" },
    { value: 60, label: "1 hour" },
    { value: 75, label: "1 hr, 15 min" },
    { value: 90, label: "1 hr, 30 min" },
    { value: 105, label: "1 hr, 45 mins" },
    { value: 120, label: "2 hours" },
];

const technicalDepthList = ['L100-Foundational',
    'L200-Aspiring',
    'L300-Applied',
    'L400-Mature']


const meetingTypeList = [
    {
        label: "Customer Briefing (non-EBC)",
        value: "Customer Briefing (non-EBC)",
    },
    {
        label: "Roadmap",
        value: "Roadmap",
    },
    {
        label: "Rapid Prototype (POC)",
        value: "Rapid Prototype (POC)",
    },
    {
        label: "Technical workshop",
        value: "Technical workshop",
    },
    {
        label: "Architecture design",
        value: "Architecture design",
    },
    {
        label: "Internal Microsoft Event/Conference",
        value: "Internal Microsoft Event/Conference",
    },
    {
        label: "External Event/Conference",
        value: "External Event/Conference",
    }
]

const topiclist = [
    { label: 'Azure Machine Learning Platform', serviceOptions: ['Azure Machine Learning', 'Responsible AI with Azure'] },
    { label: 'Azure AI Services', serviceOptions: ['Azure AI Services', 'Azure AI Bot Service', 'Azure AI Decision', 'Azure AI Document Intelligence', 'Azure AI Immersive Reader', 'Azure AI Language', 'Azure AI Metrics Advisor', 'Azure AI Speech', 'Azure AI Video Indexer', 'Azure AI Vision', 'Azure Cognitive Search', 'Azure OpenAI Service'] },
    { label: 'Custom AI/ML topic', serviceOptions: [] },
];

const industryList = [
    { label: 'Retail', value: 'Retail' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Consumer Goods', value: 'Consumer Goods' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Aviation', value: 'Aviation' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Media and Entertainment', value: 'Media and Entertainment' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Gaming', value: 'Gaming' },
    { label: 'Sustainability', value: 'Sustainability' }
];

const aiMaturityLevelList = [
    { label: 'Beginner (Emerging AI Capability)', value: 'Beginner (Emerging AI Capability)' },
    { label: 'Intermediate (Developing AI Capability)', value: 'Intermediate (Developing AI Capability)' },
    { label: 'Advanced (Mature AI Capability)', value: 'Advanced (Mature AI Capability)' }
];

const attendeesTypeList = [
    'Speaker',
    'Observer',
    'Special',
    'Executive',
    'Owner',
    'EPS',
]

const timeHorizonList = ['1-3 months', '4-6 months', '7-9 months', '10-12 months', '12+ months', 'NA'];

// let listAttendees = [];

export default function PersistentDrawerRight() {
    const navigate = useNavigate()
    const theme = useTheme();
    // eslint-disable-next-line no-unused-vars
    const { instance, inProgress, accounts } = useMsal();
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [triageEdit, setTriageEdit] = React.useState(sessionStorage.getItem("triageEdit") === "yes" ? true : false);
    const [open, setOpen] = React.useState(true);
    const [attachment, setAttachment] = React.useState(null);
    const [attachmentName, setAttachmentName] = React.useState(null);
    const [loader, setLoader] = React.useState(false);
    const [statusSelected, setStatusSelected] = React.useState('');
    const [cardInfo, setCardInfo] = React.useState(null);
    const [visible, setVisible] = React.useState('No');
    const [autoEmail, setAutoEmail] = React.useState('No');
    const [title, setTitle] = React.useState(null);
    const [style, setStyle] = React.useState(null);
    const [meetingFormat, setMeetingFormat] = React.useState(null);
    const [meetingType, setMeetingType] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [meetingLink, setMeetingLink] = React.useState(null);
    const [flexibility, setFlexibility] = React.useState(null);
    const [timeNote, setTimeNote] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [timeZone, setTimeZone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [duration, setDuration] = React.useState(null);
    const [technicalDepth, setTechnicalDepth] = React.useState(null);
    const [observable, setObservable] = React.useState(null);
    const [teSponser, setTeSponser] = React.useState(false);
    const [customerName, setCustomerName] = React.useState(null);
    const [isPartner, setIsPartner] = React.useState(false);
    const [briefingId, setBriefingId] = React.useState(null);
    const [confirmationDelete, setConfirmationDelete] = React.useState(false);
    const [updatedAttachment, setUpdatedAttachment] = React.useState(false);
    const [openMailHistory, setOpenMailHisory] = React.useState(false);

    const [session, setSession] = React.useState(null);
    const [topic, setTopic] = React.useState(null);
    const [timeHorizon, setTimeHorizon] = React.useState(null);
    const [attendees, setAttendees] = React.useState(null);
    const [linkedInBios, setlinkedInBios] = React.useState(null);
    const [engagementFocus, setengagementFocus] = React.useState(null);
    const [microsoftFocus, setmicrosoftFocus] = React.useState(null);
    const [customerFocus, setcustomerFocus] = React.useState(null);
    const [customTopicTitle, setCustomTopicTitle] = React.useState(null);
    // eslint-disable-next-line no-unused-vars
    const [searchAttendee, setSearchAttendee] = React.useState(null);
    const [searchAttendeeType, setSearchAttendeeType] = React.useState('Speaker');
    const [listAttendees, setlistAttendees] = React.useState([]);
    const [SelectedAttendee, setSelectedAttendee] = React.useState(null);
    const [opportunity, setOpportunity] = React.useState(null);
    const [comments, setComments] = React.useState(null);
    const [previousComments, setPreviousComments] = React.useState('');
    const [opportunitiesList, setOpportunitiesList] = React.useState([]);
    const [serviceInterestOptions, setServiceInterestOptions] = React.useState([]);
    const [digitalNative, setDigitalNative] = React.useState(false);
    const [disableDigital, setDisableDigital] = React.useState(false);
    const [customer500, setCustomer500] = React.useState(false);
    const [executiveRequestForm, setExecutiveRequestForm] = React.useState(false);
    const [industry, setIndustry] = React.useState(null);
    const [aiMaturityLevel, setAiMaturityLevel] = React.useState(null);
    const [appliedOpenAIService, setAppliedOpenAIService] = React.useState(null);
    const [receivedOpenAIService, setReceivedOpenAIService] = React.useState(null);

    const [observationLimit, setObservationLimit] = React.useState(0);
    // eslint-disable-next-line no-unused-vars
    const [searchAttendeeList, setSearchAttendeeList] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [addAttendeeClicked, setAddAttendeeClicked] = React.useState(false);

    const [open200, setOpen200] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openRequiredfields, setOpenRequiredFields] = React.useState(false)
    const [buttonClicked, setButtonClicked] = React.useState(false)

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        // Only allow numbers
        if (!isNaN(newValue)) {
            setObservationLimit(newValue);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleFileChange = (event) => {
        try {
            const file = event.target.files[0];
            setAttachmentName(file.name);
            setAttachment(file);
            setUpdatedAttachment(true);
        }
        catch {

        }
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setAttachmentName(file.name);
        setAttachment(file);
        setUpdatedAttachment(true);
    };

    const FileDropzone = ({ onDrop, children }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

        return (
            <div {...getRootProps()} className={`dropzone ${isDragActive && 'active'}`}>
                <input {...getInputProps()} />
                {children}
            </div>
        );
    };

    const handleAddAttendee = () => {
        setAddAttendeeClicked(true);
        const formData = new FormData();
        var requestId = cardInfo ? cardInfo[0].fields['Custom.RequestID'] : ''
        if (SelectedAttendee && SelectedAttendee?.length > 0) {
            formData.append("request_id", requestId);
            formData.append("name", SelectedAttendee[0]['displayName']);
            formData.append("type", searchAttendeeType);
            formData.append("email", SelectedAttendee[0]['mail']);
            const config = {
                headers: {
                    'content-type': 'application/json',
                }
            };
            axios.post(BaseConfig.apiBaseUrl + 'triage/addAttendee/' + sessionStorage.getItem('Emailid'), formData, config)
                .then(response => {
                    if (response.data.status) {
                        getAttendees(requestId)
                        setSelectedAttendee(null);
                        setSearchAttendee(null);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    };

    const handleAttendeeDelete = (id) => {
        var requestId = cardInfo ? cardInfo[0].fields['Custom.RequestID'] : ''
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.delete(BaseConfig.apiBaseUrl + 'triage/deleteAttendeeOpportunity/' + sessionStorage.getItem('Emailid') + '/' + id, config)
            .then(response => {
                if (response.data.status) {
                    getAttendees(requestId)
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const handleRequestDelete = () => {
        var requestId = cardInfo ? cardInfo[0].fields['Custom.RequestID'] : ''
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.delete(BaseConfig.apiBaseUrl + 'triage/deleteRequest/' + sessionStorage.getItem('Emailid') + '/' + sessionStorage.getItem('requestId') + '/' + requestId, config)
            .then(response => {
                if (response.data.status) {
                    navigate('/home');
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const OpenConfirmationPopup = () => {
        setConfirmationDelete(true)
    };

    const handleMailHistoryClose = () => {
        setOpenMailHisory(false)
    }

    const handleOpportunityDelete = (id) => {
        var requestId = cardInfo ? cardInfo[0].fields['Custom.RequestID'] : ''
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.delete(BaseConfig.apiBaseUrl + 'triage/deleteAttendeeOpportunity/' + sessionStorage.getItem('Emailid') + '/' + id, config)
            .then(response => {
                if (response.data.status) {
                    getOpportunity(requestId);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const onEditorStateChange = (label, editorState) => {
        if (editorState.replace(/<[^>]+>/g, '')?.trim() === '') {
            editorState = ''
        }
        if (label === 'customerObjectives') {
            setcustomerFocus(editorState)
        }
        if (label === 'microsoftObjectives') {
            setmicrosoftFocus(editorState)
        }
        if (label === 'engagementFocus') {
            setengagementFocus(editorState)
        }
        if (label === 'timeNote') {
            setTimeNote(editorState)
        }
        if (label === 'location') {
            setLocation(editorState)
        }
        if (label === 'meetingLink') {
            setMeetingLink(editorState)
        }
        if (label === 'linkedInBios') {
            setlinkedInBios(editorState)
        }
        if (label === 'comments') {
            setComments(editorState)
        }
    };

    const getAttendees = (requestID) => {
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.get(BaseConfig.apiBaseUrl + 'triage/getAttendies/' + sessionStorage.getItem('Emailid') + '/' + requestID, config)
            .then(response => {
                if (response.data.status) {
                    setlistAttendees(response.data.data.value);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const getOpportunity = (requestID) => {
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.get(BaseConfig.apiBaseUrl + 'triage/getOpportunities/' + sessionStorage.getItem('Emailid') + '/' + requestID, config)
            .then(response => {
                if (response.data.status) {
                    setOpportunitiesList(response.data.data.value);
                }
            })
            .catch(function (error) {
                console.error(error);
                setOpportunitiesList([]);
            });
    }

    const getCardDetailsById = async () => {
        const formData = new FormData();
        formData.append("user_id", sessionStorage.getItem('Emailid'));
        formData.append("id", sessionStorage.getItem('requestId'));
        let didReceiveResponse = false; // Flag to check if a response was received
        try {
            const url = BaseConfig.apiBaseUrl + 'triage/getRequestInfoById';
            const responsePromise = axios.post(url, formData, {
                headers: {
                    "Content-Type": undefined,
                },
            });
            const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error("Request timed out"));
                }, 4000);
            });
            const response = await Promise.race([responsePromise, timeoutPromise]);
            if (response.data.status === "200") {
                setCardInfo(response.data.data.value);
                setStatusSelected(response.data.data.value[0].fields['Custom.RequestStatus'] || '');
                setTitle(response.data.data.value[0].fields['System.Title'] || '');
                setStyle(response.data.data.value[0].fields['Custom.EngagementStyle'] || '');
                setMeetingFormat(response.data.data.value[0].fields['Custom.MeetingFormat'] || '');
                setLocation(response.data.data.value[0].fields['Custom.LocationField'] || '');
                setMeetingType(response.data.data.value[0].fields['Custom.RequestEngagementType'] || '');
                setMeetingLink(response.data.data.value[0].fields['Custom.MeetingsLink'] || '');
                setMeetingFormat(response.data.data.value[0].fields['Custom.MeetingFormat'] || '');
                setFlexibility(response.data.data.value[0].fields['Custom.RequestedDateSpan'] || '');
                setTimeNote(response.data.data.value[0].fields['Custom.TimeNotes'] || '');
                setFromDate(response.data.data.value[0].fields && response.data.data.value[0].fields['Custom.FromDate']?.length > 3 ? response.data.data.value[0].fields['Custom.FromDate'].slice(0, -4) : null);
                setToDate(response.data.data.value[0].fields && response.data.data.value[0].fields['Custom.ToDate']?.length > 3 ? response.data.data.value[0].fields['Custom.ToDate'].slice(0, -4) : null);
                setTimeZone(response.data.data.value[0].fields['Custom.Timezone'] || '');
                setDuration(response.data.data.value[0].fields['Custom.RequestTimeDuration'] || '');
                setTechnicalDepth(response.data.data.value[0].fields['Custom.TechnicalDepth'] || '');
                setTeSponser(response.data.data.value[0].fields['Custom.TEwillbecoveredbysponsor'] || false);
                setCustomerName(response.data.data.value[0].fields['Custom.CustomersName'] || '');
                setIsPartner(response.data.data.value[0].fields['Custom.IsCustomerPartner'] || false);
                setTopic(response.data.data.value[0].fields['Custom.AITopics'] || '');
                const selectedItem = topiclist.find(item => item.label === (response.data.data.value[0].fields['Custom.AITopics'] || ''));
                setServiceInterestOptions(selectedItem ? selectedItem.serviceOptions : []);
                setCustomer500(response.data.data.value[0].fields['Custom.S500Customer'] || false);
                setDigitalNative(response.data.data.value[0].fields['Custom.DigitalNative'] || false);
                setExecutiveRequestForm(response.data.data.value[0].fields['Custom.ExecutiveRequestFormCompleted'] || false);
                setOpportunity(response.data.data.value[0].fields['Custom.IsOpportunity'] ? 'Yes' : 'No');

                setSession(response.data.data.value[0].fields['Custom.ServiceInterest'] || '');
                setTimeHorizon(response.data.data.value[0].fields['Custom.Timehorizontoreachproduction'] || '');
                setAttendees(response.data.data.value[0].fields['Custom.AIAttendees_PL'] || '');
                setlinkedInBios(response.data.data.value[0].fields['Custom.LinkedInBios'] || '');
                setengagementFocus(response.data.data.value[0].fields['Custom.EngagementFocus'] || '');
                setmicrosoftFocus(response.data.data.value[0].fields['Custom.MicrosoftObjectives'] || '');
                setcustomerFocus(response.data.data.value[0].fields['Custom.CustomerObjectives'] || '');
                setVisible(response.data.data.value[0].fields['Custom.IsVisible'] ? 'Yes' : 'No');
                setAutoEmail(response.data.data.value[0].fields['Custom.RequestAutoEmail'] ? 'Yes' : 'No');
                setObservable(response.data.data.value[0].fields['Custom.IsObservable'] ? 'Yes' : 'No');
                setObservationLimit(response.data.data.value[0].fields['Custom.RequestObservationLimit'] || '');
                setCustomTopicTitle(response.data.data.value[0].fields['Custom.CustomTopicTitle'] || '');
                setAttachmentName(response.data.data.value[0].fields['Custom.CurrentAttachmentName'] || '');
                setBriefingId(response.data.data.value[0].fields['Custom.BriefingID'] || '');
                setPreviousComments(response.data.data.value[0].fields['Custom.Comment'] || '')

                setIndustry(response.data.data.value[0].fields['Custom.CustomerIndustry'] || '')
                setAiMaturityLevel(response.data.data.value[0].fields['Custom.AIMaturityLevel'] || '')
                setAppliedOpenAIService(response.data.data.value[0].fields['Custom.CustomerappliedtoAOAI'] || '')
                setReceivedOpenAIService(response.data.data.value[0].fields['Custom.CustomerhasaccesstoAOAI'] || '')

                // if (response.data.data.value[0].fields['Custom.AttendeeName']) {
                //     const name = response.data.data.value[0].fields['Custom.AttendeeName'];
                //     const type = response.data.data.value[0].fields['Custom.AttendeeTypes'];
                //     setlistAttendees([{ name, type }]);
                // }
                getAttendees(response.data.data.value[0].fields['Custom.RequestID'] || []);
                getOpportunity(response.data.data.value[0].fields['Custom.RequestID'] || []);

                if (response.data.data.value[0]?.fields['Custom.RequestEngagementType'] === "Customer Briefing (non-EBC)" || response.data.data.value[0]?.fields['Custom.RequestEngagementType'] === "Roadmap" || response.data.data.value[0]?.fields['Custom.RequestEngagementType'] === "Rapid Prototype (POC)" || response.data.data.value[0]?.fields['Custom.RequestEngagementType'] === "Technical workshop" || response.data.data.value[0]?.fields['Custom.RequestEngagementType'] === "Architecture design") {
                    setDisableDigital(false);
                }
                else {
                    setDisableDigital(true);
                }

                didReceiveResponse = true;
            }
        } catch (error) {
            console.error("Failed to create request", error);
        }
        if (!didReceiveResponse) {
            setCardInfo([]);
        }
    };

    const update = () => {
        setLoader(true)
        const timeZoneLabel = timeZone ? document.getElementById("timeZone")?.innerText : '';
        const formData = new FormData();
        formData.append("user_name", sessionStorage.getItem('userName'));
        formData.append("request_id", sessionStorage.getItem('requestId'));
        if (style) {
            formData.append("style", style);
        }
        if (comments) {
            let updatedComments
            const currentDate = new Date().toLocaleString(undefined, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            if (previousComments === '' || !previousComments) {
                const formattedComments = comments.replace(/<p>/g, '').replace(/<\/p>/g, ' ').replace(/<br>/g, '').replace(/<p><br><\/p>/g, '');
                updatedComments = `[${currentDate}] ${sessionStorage.getItem('userName')}: ${formattedComments}`;
            }
            else {
                const formattedComments = comments.replace(/<p>/g, '').replace(/<\/p>/g, ' ').replace(/<br>/g, '').replace(/<p><br><\/p>/g, '');
                updatedComments = `[${currentDate}] ${sessionStorage.getItem('userName')}: ${formattedComments}<br>${previousComments}`;
            }
            formData.append("comments", updatedComments);
        }
        formData.append("title", title);
        formData.append("Status", statusSelected);
        formData.append("Visible", visible === 'Yes' ? true : false);
        formData.append("autoEmail", autoEmail === 'Yes' ? true : false);
        formData.append("observable", observable === 'Yes' ? true : false);
        formData.append("observation_limit", observationLimit);
        formData.append("customer_name", customerName);
        formData.append("briefing_id", briefingId);
        formData.append("time_horizon", timeHorizon);
        formData.append("executiveRequestForm", executiveRequestForm);
        formData.append("Customer500", customer500);
        formData.append("digitalNative", digitalNative);
        if (attachment) {
            formData.append("attachment", attachment);
        }
        formData.append("time_zone", timeZone);
        formData.append("time_zone_label", timeZoneLabel || '');
        if (customTopicTitle !== '') {
            formData.append("custom_topic_title", customTopicTitle);
        }
        else {
            formData.append("custom_topic_title", topic);
        }
        formData.append("is_customer_partner", isPartner);
        formData.append("meeting_Type", meetingType);
        formData.append("meeting_format", meetingFormat);
        formData.append("te_covered_sponsor", teSponser);
        formData.append("meeting_link", meetingLink);
        formData.append("location", location);
        formData.append("flexibility", flexibility);
        formData.append("time_notes", timeNote);
        if (fromDate) {
            formData.append("from_date", fromDate);
        }
        if (toDate) {
            formData.append("to_date", toDate);
        }
        formData.append("duration", duration);
        formData.append("technical_depth", technicalDepth);
        formData.append("topic", topic);
        formData.append("service", session);
        formData.append("engagement_focus", engagementFocus);
        formData.append("customer_objectives", customerFocus);
        formData.append("microsoft_objectives", microsoftFocus);
        formData.append("linkedIn_bio", linkedInBios);
        formData.append("industry", industry);
        formData.append("aiMaturityLevel", aiMaturityLevel);
        formData.append("appliedOpenAIService", appliedOpenAIService);
        formData.append("receivedOpenAIService", receivedOpenAIService);
        formData.append("not_sales_opportunities", opportunity === 'Yes' ? true : false);
        const config = {
            headers: {
                'content-type': undefined,
            }
        };
        axios.put(BaseConfig.apiBaseUrl + 'triage/requestUpdate/' + sessionStorage.getItem('Emailid'), formData, config)
            .then(response => {
                setLoader(false);
                if (response.status === 200) {
                    setOpen200(true)
                    const delay = setTimeout(() => {
                        setOpen(false);
                        navigate('/home')
                    }, 700);

                    return () => clearTimeout(delay);
                }
                else {
                    setOpenError(true)
                }
            })
            .catch(function (error) {
                setLoader(false);
                console.error(error);
                setOpenError(true);
            });
    }

    const fetchData = async (searchString) => {
        setAddAttendeeClicked(false);
        var successfulSignIn = false;
        try {
            if (accounts.length > 0) {
                const accessTokenRequest = {
                    scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
                    account: accounts ? accounts[0] : null,
                };
                const response = await instance.acquireTokenSilent(accessTokenRequest);
                sessionStorage.setItem("userName", response.account.name);
                sessionStorage.setItem("Emailid", response.account.username);
                sessionStorage.setItem("accountId", response.account.localAccountId);
                sessionStorage.setItem("userToken", response.accessToken);
                setIsSignedIn(true)
                successfulSignIn = true
            } else {
                throw Error("not found any current active account")
            }
        } catch (error) {
            sessionStorage.clear();
            window.location.replace('/');
        }
        if (successfulSignIn) {
            setSearchAttendee(searchString)
            const url =
                `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'@microsoft.com') and (startsWith(displayName,'${searchString}') or startsWith(mail,'${searchString}'))`;
            const headers = {
                ConsistencyLevel: "eventual",
                Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
                "Content-Type": "application/json",
            };
            try {
                const response = await axios.get(url, { headers });
                setSearchAttendeeList(response.data.value)
            } catch (error) {
                console.error(error);
                setSearchAttendeeList([]);
            }
        }
    };

    const handleRequiredFields = () => {
        setButtonClicked(true);
        if (customerName && title && statusSelected && autoEmail) {
            update();
        }
        else {
            setOpenRequiredFields(true)
        }
    };

    const handleDigitalNativesChange = (event) => {
        if (meetingType === "Customer Briefing (non-EBC)" || meetingType === "Roadmap" || meetingType === "Rapid Prototype (POC)" || meetingType === "Technical workshop" || meetingType === "Architecture design") {
            setDigitalNative(event.target.checked)
            setDisableDigital(false);
        }
        else {
            setDigitalNative(false);
            setDisableDigital(true);
        }
    }

    const handleEngagementTypeChange = (event) => {
        if (event.target.value === "Customer Briefing (non-EBC)" || event.target.value === "Roadmap" || event.target.value === "Rapid Prototype (POC)" || event.target.value === "Technical workshop" || event.target.value === "Architecture design") {
            setDisableDigital(false)
        }
        else {
            setDisableDigital(true)
            setDigitalNative(false)
        }
        setMeetingType(event.target.value)
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const loginProcess = async () => {
            var successfulSignIn = false;
            try {
                if (accounts.length > 0) {
                    const accessTokenRequest = {
                        scopes: ["https://graph.microsoft.com/.default"],
                        account: accounts ? accounts[0] : null,
                    };
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    sessionStorage.setItem("userName", response.account.name);
                    sessionStorage.setItem("Emailid", response.account.username);
                    sessionStorage.setItem("accountId", response.account.localAccountId);
                    sessionStorage.setItem("userToken", response.accessToken);
                    setIsSignedIn(true)
                    successfulSignIn = true
                } else {
                    throw Error("not found any current active account")
                }
            } catch (error) {
                console.log('Error acquiring token silently:', error);
                sessionStorage.clear();
                window.location.replace('/');
            }
            if (successfulSignIn) {
                getCardDetailsById();
                setOpen200(false);
                setOpenError(false);
            }
        }
        loginProcess();
        // eslint-disable-next-line
    }, []);

    console.log("digital", disableDigital);

    try {
        return (
            <>
                {isSignedIn &&
                    <Box sx={{ display: 'flex' }}>
                        <Navbar portalName={"- Engagement Requests"}></Navbar>
                        <MenubarForm handleRequiredFields={handleRequiredFields} OpenConfirmationPopup={OpenConfirmationPopup} ></MenubarForm>
                        <Main open={open}>
                            <DrawerHeader />
                            <div>
                                <Grid container>
                                    <Grid item xs={11} style={{ fontSize: '15px', marginBottom: '10px', marginTop: '50px' }}>
                                        <b> Comments </b>
                                    </Grid>
                                    <Grid item xs={1} container justifyContent="flex-end">
                                        <IconButton aria-label="circular button">
                                            <Avatar style={{ backgroundColor: '#3c97ff' }} onClick={open ? handleDrawerClose : handleDrawerOpen} >
                                                {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                                            </Avatar>
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <div>
                                    {/* <TextField
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            rows={3}
                            style={{ width: '100%' }}
                        /> */}
                                    <ReactQuill
                                        theme="snow"
                                        value={comments}
                                        placeholder='Enter Comments'
                                        onChange={(editorState) => { onEditorStateChange("comments", editorState) }}
                                    />
                                    <div
                                        style={{
                                            lineHeight: '1.2',
                                            fontSize: '12.5px',
                                            maxHeight: '90px', // Replace '300px' with your desired fixed height
                                            overflow: 'auto', // Use 'scroll' if you want to always show the scrollbar, or 'auto' for automatic scrollbar when needed
                                        }}
                                        dangerouslySetInnerHTML={{ __html: previousComments }}
                                    />
                                </div>

                                <Grid container paddingTop={3} paddingBottom={3} >
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Status *</b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={statusSelected}
                                                    onChange={(event) => { setStatusSelected(event.target.value) }}
                                                >
                                                    {statuslist.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Visible </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={visible}
                                                    onChange={(event) => { setVisible(event.target.value) }}
                                                >
                                                    {VisibleList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Auto Email * </b></div>
                                        <div >

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={autoEmail}
                                                    onChange={(event) => { setAutoEmail(event.target.value) }}
                                                >
                                                    {autoEmailList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Observable </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={observable}
                                                    onChange={(event) => { setObservable(event.target.value) }}
                                                >
                                                    {observableList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Observation Limit </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <TextField
                                                type="text"
                                                size='small'
                                                fullWidth
                                                value={observationLimit}
                                                onChange={handleInputChange}
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         // <InputAdornment position="start">
                                            //         //     <IconButton onClick={handleIncrement} edge="end">
                                            //         //         <ArrowUpwardIcon />
                                            //         //     </IconButton>
                                            //         //     <IconButton onClick={handleDecrement} edge="end">
                                            //         //         <ArrowDownwardIcon />
                                            //         //     </IconButton>
                                            //         // </InputAdornment>
                                            //     ),
                                            // }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Id </b></div>
                                        <div style={{ paddingTop: '10px' }}>
                                            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> {cardInfo ? cardInfo[0]?.fields['Custom.RequestID'] : ''} </b></div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Customer Name * </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter Customer Name"
                                                value={customerName}
                                                error={buttonClicked && (customerName === '' || !customerName)}
                                                helperText={buttonClicked && (customerName === '' || !customerName) ? '*Required' : ''}
                                                required
                                                onChange={(event) => { setCustomerName(event.target.value) }}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '27px' }}><b> </b></div>
                                        <div >
                                            <FormControlLabel
                                                control={<Checkbox name="isPartner" checked={isPartner} onChange={(event) => setIsPartner(event.target.checked)} />}
                                                label={<span style={{ fontSize: '14px' }}><b>Customer is a Partner/GISV/ISV</b></span>} />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '27px' }}><b> </b></div>
                                        <div >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="customer500"
                                                        checked={customer500} onChange={(event) => setCustomer500(event.target.checked)}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}><b>S500 Customer</b></span>} />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '27px' }}><b> </b></div>
                                        <div >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="digitalNative"
                                                        checked={digitalNative}
                                                        onChange={handleDigitalNativesChange}
                                                        disabled={disableDigital}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}><b>Digital Native/Startup/ISV</b></span>}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Industry </b></div>
                                        <div>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={industry}
                                                    onChange={(event) => { setIndustry(event.target.value) }}
                                                >
                                                    {industryList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> AI Maturity Level </b></div>
                                        <div style={{ paddingRight: '30px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={aiMaturityLevel}
                                                    onChange={(event) => { setAiMaturityLevel(event.target.value) }}
                                                >
                                                    {aiMaturityLevelList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Have they applied to Azure Open AI service? </b></div>
                                        <div style={{ paddingRight: '30px' }}>
                                            <RadioGroup
                                                id="marketingCampaign"
                                                name="marketingCampaign"
                                                value={appliedOpenAIService}
                                                onChange={(event) => { setAppliedOpenAIService(event.target.value) }}
                                            >
                                                <div style={{display:'flex', paddingRight:'20px'}}>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label='Yes'
                                                        style={{paddingRight:'10px'}}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label='No'
                                                    />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>Have they received access to Azure Open AI service?</b></div>
                                        <div>
                                            <RadioGroup
                                                id="marketingCampaign"
                                                name="marketingCampaign"
                                                value={receivedOpenAIService}
                                                onChange={(event) => { setReceivedOpenAIService(event.target.value) }}
                                            >
                                                <div style={{display:'flex', paddingRight:'20px'}}>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label='Yes'
                                                        style={{paddingRight:'10px'}}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label='No'
                                                    />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>

                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={8}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Title * </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter Title"
                                                style={{ width: '100%' }}
                                                value={title}
                                                error={buttonClicked && (title === '' || !title)}
                                                helperText={buttonClicked && (title === '' || !title) ? '*Required' : ''}
                                                required
                                                onChange={(event) => { setTitle(event.target.value) }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Style </b></div>
                                        <div >

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={style}
                                                    onChange={(event) => { setStyle(event.target.value) }}
                                                >
                                                    {styleList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={8}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Engagement type </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={meetingType}
                                                    onChange={handleEngagementTypeChange}
                                                >
                                                    {meetingTypeList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Briefing Id </b></div>
                                        <div >
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter Briefing Id"
                                                style={{ width: '100%' }}
                                                value={briefingId}
                                                onChange={(event) => { setBriefingId(event.target.value) }}
                                            />
                                        </div>
                                    </Grid>

                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={8}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Meeting Format </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={meetingFormat}
                                                    onChange={(event) => { setMeetingFormat(event.target.value) }}
                                                >
                                                    {meetingFormatList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '11px', marginBottom: '27px' }}><b> </b></div>
                                        <div >
                                            <FormControlLabel
                                                control={<Checkbox name="te_sponser" checked={teSponser} onChange={(event) => setTeSponser(event.target.checked)} />}
                                                label={<span style={{ fontSize: '14px' }}><b>T&E covered by Sponser</b></span>} />
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={7}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Location </b></div>
                                        <div>
                                            {/* <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={location}
                                    onChange={(event) => { setLocation(event.target.value) }}
                                /> */}
                                            <ReactQuill
                                                theme="snow"
                                                value={location}
                                                placeholder="Enter Location"
                                                style={{ maxWidth: '800px', height: '89px' }}
                                                onChange={(editorState) => { onEditorStateChange('location', editorState) }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={7}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Meeting Link </b></div>
                                        <div>
                                            {/* <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={meetingLink}
                                    onChange={(event) => { setMeetingLink(event.target.value) }}
                                /> */}
                                            <ReactQuill
                                                theme="snow"
                                                value={meetingLink}
                                                style={{maxWidth: '800px', height: '89px' }}
                                                placeholder="Enter Meeting Link"
                                                onChange={(editorState) => { onEditorStateChange('meetingLink', editorState) }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Flexibility </b></div>
                                        <div>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={flexibility}
                                                    onChange={(event) => { setFlexibility(event.target.value) }}
                                                >
                                                    {flexibilityList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={8}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Time Notes </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            {/* <TextField
                                    id="outlined-multiline-static"
                                    label=""
                                    multiline
                                    rows={4}
                                    value={timeNote}
                                    onChange={(event) => { setTimeNote(event.target.value) }}
                                    style={{ width: '100%' }}
                                /> */}
                                            <ReactQuill
                                                theme="snow"
                                                style={{ maxWidth: '800px', height: '89px' }}
                                                value={timeNote}
                                                placeholder="Please provide any notes regarding date, time, or duration flexibility that may increase acceptance."
                                                onChange={(editorState) => { onEditorStateChange('timeNote', editorState) }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> From Date </b></div>
                                        <div style={{ paddingBottom: '16px' }}>
                                            <TextField
                                                id="fromDate"
                                                name="fromDate"
                                                type="datetime-local"
                                                size='small'
                                                value={fromDate}
                                                onChange={(event) => { setFromDate(event.target.value) }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            // error={!!errors.fromDate}
                                            // helperText={errors.fromDate}
                                            // required
                                            // disabled={this.state.requestedDate === "Flexible"} // Disable if requestedDate is "Flexible"
                                            />
                                        </div>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> To Date </b></div>
                                        <div >
                                            <TextField
                                                id="toDate"
                                                name="toDate"
                                                type="datetime-local"
                                                size='small'
                                                value={toDate}
                                                onChange={(event) => { setToDate(event.target.value) }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            // error={!!errors.fromDate}
                                            // helperText={errors.fromDate}
                                            // required
                                            // disabled={this.state.requestedDate === "Flexible"} // Disable if requestedDate is "Flexible"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={8}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Time Zone </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TimezoneSelect
                                                id="timeZone"
                                                name="timeZone"
                                                placeholder="Select timezone"
                                                styles={customStyles}
                                                value={timeZone}
                                                onChange={(timezone) => { setTimeZone(timezone.value) }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Duration </b></div>
                                        <div >
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={duration}
                                                    onChange={(event) => { setDuration(event.target.value) }}
                                                >
                                                    {durationList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    {/* <Grid xs={4}>
                            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Technical Depth </b></div>
                            <div >

                                <FormControl sx={{ width: '100%' }} size="small">
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={technicalDepth}
                                    >
                                        {technicalDepthList.map((item) => (
                                            <MenuItem value={item}> {item} </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid> */}
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Opportunity </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={opportunity}
                                                    placeholder="Select Opportunity"
                                                    onChange={(event) => { setOpportunity(event.target.value) }}
                                                >
                                                    {opportunityList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '20px' }}><b> Opportunity Id: </b></div>
                                        <div >
                                            {cardInfo ? cardInfo[0]?.fields['Custom.OpportunityID'] : ''}
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>

                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Technical Depth </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={technicalDepth}
                                                    onChange={(event) => { setTechnicalDepth(event.target.value) }}
                                                >
                                                    {technicalDepthList.map((item) => (
                                                        <MenuItem value={item}> {item} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Topic </b></div>
                                        <div style={{ paddingRight: '20px' }}>

                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={topic}
                                                    onChange={(event) => {
                                                        // eslint-disable-next-line
                                                        const { value, name } = event.target;
                                                        const selectedItem = topiclist.find(item => item.label === value);
                                                        setTopic(selectedItem.label);
                                                        setSession('');
                                                        if (!(selectedItem.label === 'Custom AI/ML topic')) {
                                                            setCustomTopicTitle('')
                                                        }
                                                        setServiceInterestOptions(selectedItem.serviceOptions);
                                                    }}
                                                >
                                                    {topiclist.map((item) => (
                                                        <MenuItem value={item.label}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Service </b></div>
                                        <div >
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={session}
                                                    onChange={(event) => { setSession(event.target.value) }}
                                                >
                                                    {serviceInterestOptions.map((item) => (
                                                        <MenuItem value={item}> {item} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ marginBottom: '30px' }}>
                                    <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Custom Topic Title </b></div>
                                    <div >
                                        <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            style={{ width: '100%' }}
                                            placeholder="Enter Custom Topic Title"
                                            value={customTopicTitle}
                                            onChange={(event) => { setCustomTopicTitle(event.target.value) }}
                                            inputProps={{ maxLength: 100 }}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: '30px' }}>
                                    <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> What is the expected time horizon for this workload to reach production? </b></div>
                                    <div >
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={timeHorizon}
                                                onChange={(event) => { setTimeHorizon(event.target.value) }}
                                            >
                                                {timeHorizonList.map((item) => (
                                                    <MenuItem value={item}> {item} </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div style={{ marginBottom: '30px' }}>
                                    <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Attendees </b></div>
                                    <div >
                                        <TextField
                                            id="outlined-multiline-static"
                                            label=""
                                            multiline
                                            placeholder="Enter Attendees"
                                            rows={2}
                                            value={attendees}
                                            onChange={(event) => { setAttendees(event.target.value) }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>

                                <Grid container paddingBottom={7}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> LinkedIn Bios </b></div>
                                        <div>
                                            {/* <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    value={linkedInBios}
                                    onChange={(event) => { setlinkedInBios(event.target.value) }}
                                    style={{ width: '100%' }}
                                /> */}
                                            <ReactQuill
                                                theme="snow"
                                                placeholder="Enter LinkedIn Bios"
                                                value={linkedInBios}
                                                style={{maxWidth: '800px', height: '89px' }}
                                                onChange={(editorState) => { onEditorStateChange('linkedInBios', editorState) }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={8} style={{ paddingRight: '20px' }}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Attachments </b></div>
                                        <Grid container>
                                            <Grid xs={10}>
                                                <FileDropzone onDrop={handleDrop}>
                                                    <input
                                                        type="text"
                                                        placeholder="Drag and Drop Attachment"
                                                        readOnly
                                                        className="file-name"
                                                        style={{ width: '95%', height: '40px' }}
                                                    />
                                                </FileDropzone>
                                                {updatedAttachment ?
                                                    <span style={{ fontSize: '13px' }}>{attachmentName}  <i style={{ color: 'red' }}>(unsaved)</i></span>
                                                    :
                                                    <MUILink href={cardInfo ? cardInfo[0]?.fields['Custom.CurrentAttachmentUrl'] + `?fileName=${cardInfo[0]?.fields['Custom.CurrentAttachmentName']}&download={download}&api-version=7.0` : ''}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'inherit' }}
                                                    >
                                                        <span style={{ fontSize: '13px' }}>{attachmentName}</span>
                                                    </MUILink>
                                                }
                                            </Grid>
                                            <Grid xs={2}>
                                                <label className="upload-btn">
                                                    Upload
                                                    <input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '27px' }}><b> </b></div>
                                        <div >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="executiveRequestForm"
                                                        checked={executiveRequestForm} onChange={(event) => setExecutiveRequestForm(event.target.checked)}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}><b>Executive Request Form Completed?</b></span>} />
                                        </div>
                                    </Grid>
                                </Grid>


                                <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Engagement Focus </b></div>
                                <div >
                                    {/* <TextField
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            rows={3}
                            value={engagementFocus}
                            onChange={(event) => { setengagementFocus(event.target.value) }}
                            style={{ width: '100%' }}
                        /> */}
                                    <ReactQuill
                                        theme="snow"
                                        value={engagementFocus}
                                        placeholder="Enter Engagement Focus"
                                        onChange={(editorState) => { onEditorStateChange("engagementFocus", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '60px' }}><b> Microsoft Objectives </b></div>
                                <div>
                                    {/* <TextField
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            rows={3}
                            defaultValue=""
                            value={microsoftFocus}
                            onChange={(event) => { setmicrosoftFocus(event.target.value) }}
                            style={{ width: '100%' }}
                        /> */}
                                    <ReactQuill
                                        theme="snow"
                                        value={microsoftFocus}
                                        placeholder="Enter Microsoft Objectives"
                                        onChange={(editorState) => { onEditorStateChange("microsoftObjectives", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '60px' }}><b> Customer Objectives </b></div>
                                <div >
                                    {/* <TextField
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            rows={3}
                            defaultValue=""
                            value={customerFocus}
                            onChange={(event) => { setcustomerFocus(event.target.value) }}
                            style={{ width: '100%' }}
                        /> */}
                                    <ReactQuill
                                        theme="snow"
                                        value={customerFocus}
                                        placeholder="Enter Customer Objectives"
                                        onChange={(editorState) => { onEditorStateChange("customerObjectives", editorState) }}
                                        style={{ maxWidth: '800px' , height: '200px' }}
                                    />
                                </div>

                                <Grid container paddingTop={1} paddingBottom={3}>
                                    <Grid xs={8}>

                                    </Grid>

                                    <Grid xs={3} />

                                    <Grid xs={1}>
                                        {/* <div style={{ marginTop: '30px' }}>
                                <Button variant="contained" style={{ width: '100%', fontFamily: 'Segoe UI Regular' }} onClick={handleRequiredFields} >Submit</Button>
                            </div> */}
                                    </Grid>
                                </Grid>
                            </div>
                        </Main>
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,
                                },
                            }}
                            variant="persistent"
                            anchor="right"
                            open={open}
                        >
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </DrawerHeader>
                            <div style={{ padding: '20px', paddingTop: '60px' }}>
                                <Grid container marginBottom={1}>
                                    <Grid xs={6.7}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Attendees </b></div>
                                        <div style={{ paddingRight: '10px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Reactselect
                                                    value={SelectedAttendee}
                                                    onChange={(option) => {
                                                        option ? setSelectedAttendee([{ ...option, label: option.displayName }]) : setSelectedAttendee([]);
                                                    }}
                                                    onInputChange={(searchString) => {
                                                        fetchData(searchString)
                                                    }}
                                                    isClearable
                                                    styles={reactSelectCustomStyles}
                                                    options={searchAttendeeList.map((option) => ({
                                                        value: option.displayName + " | " + option.mail,
                                                        displayName: option.displayName || "",
                                                        mail: option.mail || "",
                                                        localId: option.localId || "",
                                                        label: (
                                                            <>
                                                                <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                                <div style={{ marginTop: '0.2rem' }}>
                                                                    <div>
                                                                        <b>
                                                                            <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                        </b>
                                                                        <br />
                                                                        <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ),
                                                    }))}
                                                    placeholder="Search Attendees"
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={3.8}>
                                        <div style={{ marginTop: '30px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={searchAttendeeType}
                                                    onChange={(event) => { setSearchAttendeeType(event.target.value) }}
                                                >
                                                    {attendeesTypeList.map((item) => (
                                                        <MenuItem value={item}> {item} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid xs={1.2}>
                                        <div style={{ paddingLeft: '20px', marginTop: '30px' }}>
                                            <Avatar sx={{ bgcolor: 'primary.main' }}
                                                style={{ opacity: triageEdit ? '1' : '0.5' }}
                                                onClick={triageEdit ? handleAddAttendee : null}
                                            >
                                                +
                                            </Avatar>
                                        </div>
                                    </Grid>
                                </Grid>

                                {listAttendees !== [] && listAttendees.map((item, index) => (
                                    <Grid container display="flex" alignItems="center" justifyContent="space-between" >
                                        <Grid xs={6.7}>
                                            <div style={{ paddingRight: '10px', fontSize: '13px' }}>
                                                {item?.fields['Custom.AttendeeName']}
                                            </div>
                                        </Grid>

                                        <Grid xs={3.8}>
                                            <div style={{ fontSize: '13px' }}>
                                                {item?.fields['Custom.AttendeeTypes']}
                                            </div>
                                        </Grid>
                                        <Grid xs={1.2}>
                                            <div>
                                                <IconButton>
                                                    <Delete
                                                        style={{
                                                            color: '#3c97ff',
                                                            opacity: triageEdit ? '1' : '0.5'
                                                        }}
                                                        onClick={triageEdit ? () => handleAttendeeDelete(item.id) : null} />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                ))}

                                {/* {cardInfo && cardInfo[0].fields['Custom.AttendeeTypes'] &&
                        <Box display="flex" alignItems="center" justifyContent="space-between" paddingTop={0.8} >
                            <div style={{ fontSize: '13px' }}>{cardInfo ? cardInfo[0].fields['Custom.AttendeeName'] : ''} </div>
                            <Box display="flex" alignItems="center">
                                <div style={{ fontSize: '13px' }}> {cardInfo ? cardInfo[0].fields['Custom.AttendeeTypes'] : ''} </div>
                                <IconButton>
                                    <Delete style={{ color: '#3c97ff' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    } */}
                                {/* {listAttendees !== [] && listAttendees.map((item) => (
                        <Box display="flex" alignItems="center" justifyContent="space-between" paddingTop={0.8} >
                            <div style={{ fontSize: '13px' }}>{item.name} </div>
                            <Box display="flex" alignItems="center">
                                <div style={{ fontSize: '13px' }}> {item.type} </div>
                                <IconButton>
                                    <Delete style={{ color: '#3c97ff' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    ))} */}

                                <Divider style={{ margin: '10px 0px', marginTop: '150px' }} />

                                <div style={{ fontSize: '14px', margin: '20px 0px' }}><b> Opportunities </b></div>

                                {/* {cardInfo && cardInfo[0].fields['Custom.OpportunityAccountName'] &&
                        <Box display="flex" alignItems="center" justifyContent="space-between" >
                            <div style={{ fontSize: '13px' }}> <b> {cardInfo ? cardInfo[0].fields['Custom.OpportunityName'] : ''}</b> <br></br> {cardInfo ? cardInfo[0].fields['Custom.OpportunityAccountName'] : ''} <br></br> {cardInfo ? cardInfo[0].fields['Custom.Opportunity'] : ''} </div>
                            <Box display="flex" alignItems="center">
                                <IconButton>
                                    <Delete style={{ color: '#3c97ff' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    } */}

                                {opportunitiesList !== [] && opportunitiesList.map((item, index) => (
                                    <Box display="flex" alignItems="center" justifyContent="space-between" >
                                        <div style={{ fontSize: '13px' }}> <b> {item?.fields['Custom.Name']}</b> <br></br> {item?.fields['Custom.OpportunityAccountName']} <br></br> {item?.fields['Custom.Opportunity']} </div>
                                        <Box display="flex" alignItems="center">
                                            <IconButton>
                                                <Delete style={{ color: '#3c97ff', opacity: triageEdit ? '1' : '0.5' }} onClick={triageEdit ? () => handleOpportunityDelete(item?.id) : null} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}

                                <Divider style={{ margin: '10px 0px', marginTop: '70px' }} />

                                <div style={{ fontSize: '14px', margin: '20px 0px' }}><b> History </b></div>
                                <Link href={`https://cognitionai.visualstudio.com/EngageAI/_workitems/edit/${sessionStorage.getItem('requestId')}/`} target="_blank" rel="noopener noreferrer">
                                    Click here to view Change History
                                </Link>
                                {/* 
                            <Divider style={{ margin: '10px 0px', marginTop: '70px' }} /> */}

                                <div style={{ fontSize: '14px', margin: '10px 0px' }}><b>  </b></div>
                                <Link rel="noopener noreferrer" onClick={() => { setOpenMailHisory(true) }} style={{ cursor: 'pointer' }}>
                                    Click here to view Mail History
                                </Link>
                            </div>

                        </Drawer><Snackbar open={open200} autoHideDuration={4000} onClose={() => { setOpen200(false) }}>
                            <Alert onClose={() => { setOpen200(false) }} severity="success" sx={{ width: '100%' }}>
                                Request Updated Sucessfully
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openRequiredfields} autoHideDuration={4000} onClose={() => { setOpenRequiredFields(false) }}>
                            <Alert onClose={() => { setOpenRequiredFields(false) }} severity="error" sx={{ width: '100%' }}>
                                Fill Required Fields
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={4000} onClose={() => { setOpenError(false) }}>
                            <Alert onClose={() => { setOpenError(false) }} severity="error" sx={{ width: '100%' }}>
                                Failed to Update Request. Try Again.
                            </Alert>
                        </Snackbar>
                        <Dialog open={confirmationDelete} onClose={() => { setConfirmationDelete(false) }}>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are u sure you want to delete this request?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={() => { setConfirmationDelete(false) }} >
                                    Cancel
                                </Button>
                                <Button onClick={handleRequestDelete} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {openMailHistory && (<MailHistory handleMailHistoryClose={handleMailHistoryClose} />)}
                    </Box>
                }
                 <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}