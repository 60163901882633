exports.tableFormat = {
    headerLabels: [
        'Name',
        "Email Id",
        "Location (Azure AD)",
        "Location Supported",
        "MTC Location Supported",
        'Job Title',
        "Topics",
    ],
    keys: [
        'Name',
        "Email",
        "LocationAD",
        "Location",
        "MTCLocation",
        'Job_Title',
        "Topics",
    ]
};
