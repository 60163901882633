import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import logo from './../../assets/Images/Logo.png';
import { Avatar, Divider } from '@mui/material';
import { useState } from "react";

const drawerWidth = 337;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function Navbar(props) {
  const [isSignoutPopupOpen, setSignoutPopupOpen] = useState(false);
  let initials = null;

  try {
    const userName = sessionStorage.getItem('userName');
    if (userName !== null) {
      initials = userName
        .split(' ')
        .map(word => word.charAt(0))
        .slice(0, 2)
        .join('');
    }
  } catch (error) {

  }

  const handleLogout = () => {
    // setSignoutPopupOpen(!isSignoutPopupOpen);
  };

  const handleSignoutlink = () => {
    sessionStorage.clear();
    window.location.replace('/');
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" style={{ backgroundColor: '#f0f0f0' }}>
        <Toolbar>

          <img
            src={logo}
            alt='chatbot'
            style={{
              width: '28px',
              height: '28px',
            }}
          />
          <text style={{ fontSize: '20px', color: 'black', marginLeft: '10px', fontWeight: 'bold', fontFamily: 'Segoe UI Regular' }}>Microsoft </text>
          <Divider orientation="vertical" flexItem style={{ margin: '10px 10px' }} />
          <text style={{ fontSize: '15px', color: '#2088ff', fontWeight: 'bold', fontFamily: 'Segoe UI Regular', marginTop: '3px' }}>AI Engagement Triage {props?.portalName || ''}</text>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <div style={{ textAlign: 'Left', marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
              <Typography style={{ color: 'black', marginRight: 0, fontSize: '16px' }}>
                {sessionStorage.getItem('userName')}
              </Typography>
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                {sessionStorage.getItem('Emailid')}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
              <IconButton
                size="large"
                edge="start"
                aria-label="chatbot"
                aria-haspopup="false"
                onClick={handleLogout}
              >
                <Avatar style={{ backgroundColor: '#ac31ff' }}> {initials} </Avatar>
              </IconButton>
            </div>
            {isSignoutPopupOpen && (
              <div className="signout-popup">
                <div className="signout-popup-content">
                  <div className="user-info">
                    <div>
                      <div className="user-name">{sessionStorage.getItem('userName')}</div>
                      <div className="user-role">{sessionStorage.getItem('Emailid')}</div>
                    </div>
                  </div>
                  <Divider />
                  <div className="signout-link" onClick={handleSignoutlink}>
                    Sign Out
                  </div>
                </div>
              </div>
            )}

            {/* 
            <IconButton
              size="large"
              edge="end"
              aria-label="Settings"
              aria-haspopup="false"
            >
              <img
                src={logoutImg}
                alt='logout'
                style={{
                  width: '21px',
                  height: '21px',
                }}
              />
            </IconButton> */}
          </Box>

        </Toolbar>
      </AppBar>
    </>
  );
}