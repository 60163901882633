import { useState, useEffect } from 'react';
import { Alert, Backdrop, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Snackbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Reactselect from 'react-select';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import filterImg from './../../assets/Images/filter.png';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import BaseConfig from '../../config/config';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';
import TableComponent from './tableComponent/tablecomponent';
import MenubarfindSpeaker from './menubar';
import copy from 'clipboard-copy';
import * as XLSX from 'xlsx';
import tableFormat from '../../config/tableFormat';


const reactSelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: '40px',
    padding: '6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: '40px',
  }),
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function FindSpeaker() {
  // eslint-disable-next-line no-unused-vars
  var cityString = '';
  const { instance, inProgress, accounts } = useMsal();
  const [menuopen, setMenuopen] = useState(false);
  const [menuItemChanged, setMenuItemChanged] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [speakerData, setSpeakerData] = useState([]);

  const [suggestedCityList, setSuggestedCityList] = useState([])
  const [searchedCityName, setSearchedCityName] = useState({})

  const [mtcLocationList, setMtcLocationList] = useState([])
  const [searchedMTCLocation, setSearchedMtcLocation] = useState({})

  const [jobTitleList, setJobTitleList] = useState([])
  const [searchedJobTitle, setSearchedJobTitle] = useState([])

  const [azureMLTopicList, setAzureMLTopicList] = useState([])
  const [searchedAzureMLTopic, setSearchedAzureMLTopic] = useState([])

  const [AzureAiServicesList, setAzureAiServicesList] = useState([])
  const [searchedAzureAiService, setSearchedAzureAiService] = useState([])

  const [eraOrL100, setEraOrL100] = useState(false);
  const [azureOpenAI, setAzureOpenAI] = useState(false);
  const [responsibleAI, setResponsibleAI] = useState(false);

  const [tableLoader, setTableLoader] = useState(false);

  const [openRequiredfields, setOpenRequiredFields] = useState(false);
  const [clipboardClicked, setClipboardClicked] = useState(false);

  const [noResultFound, setNoResultFound] = useState(false);

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const setSelectAllChange = (value) => {
    setSelectAll(value)
  }

  const triggerExport = () => {
    const selectedEntries = [];

    for (const item of speakerData) {
      if (selectedIndex.includes(item.Email)) {
        selectedEntries.push(item);
      }
    }

    const excelData = selectedEntries.map(obj => {
      return tableFormat.tableFormat.keys.reduce((acc, key) => {
        if (obj.hasOwnProperty(key)) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    });

    const data1 = [tableFormat.tableFormat.headerLabels, ...excelData.map(item => Object.values(item))];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data1);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
    link.download = 'SpeakerData.xlsx';
    link.click();
  }

  const handleCopyToClipboardClick = async () => {
    let emailids = selectedIndex.join(';');
    try {
      await copy(emailids);
      setClipboardClicked(true);
      setTimeout(() => {
        setClipboardClicked(false);
      }, 2500);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  }

  const handleSearchClick = async () => {
    if (Object.keys(searchedCityName).length > 0 || Object.keys(searchedMTCLocation).length > 0) {
      setTableLoader(true);
      setSelectedIndex([])
      setSelectAll(false)
      let geoCoordinates = await getGeoCoordinates(searchedCityName.value)

      const requestData = {
        mtcLocationId: Object.keys(searchedMTCLocation).length > 0 ? searchedMTCLocation.value : 0,
        locLat: searchedMTCLocation.value ? mtcLocationList.filter(item => item.Id === searchedMTCLocation.value)[0].Latitude : geoCoordinates[0] || '',
        locLong: searchedMTCLocation.value ? mtcLocationList.filter(item => item.Id === searchedMTCLocation.value)[0].Longitude : geoCoordinates[1] || '',
        jobTitle: searchedJobTitle.map(item => item.value).join(';'),
        eraOfAIStatus: eraOrL100,
        azureOpenAIStatus: azureOpenAI,
        responsibleAIStatus: responsibleAI,
        azureAIServices: searchedAzureAiService.map(item => item.value).join(';'),
        azureMLTopics: searchedAzureMLTopic.map(item => item.value).join(';'),
      };
      const config = {
        headers: {
          'content-type': 'application/json',
        }
      };
      axios.post(BaseConfig.apiBaseUrl + 'speakerProfiles/Search', requestData, config)
        .then(response => {
          if (response.status === 200) {
            let speakerList = response.data.Data
            setSpeakerData(speakerList)
            if (speakerList.length > 0) {
              setNoResultFound(false)
            }
            else {
              setNoResultFound(true)
            }
            setTableLoader(false)
          }
        })
        .catch(function (error) {
          console.error(error);
          setTableLoader(false)
          setNoResultFound(true)
        });
    }
    else {
      setOpenRequiredFields(true)
    }
  };

  const handleClearAllFilters = () => {
    window.location.reload()
  }

  const getDropdownVlaues = () => {
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.get(BaseConfig.apiBaseUrl + 'speakerProfiles/SpeakerProfileDropdowns', config)
      .then(response => {
        if (response.data.status) {
          setAzureMLTopicList(response.data.data.AzureMLServiceTopics)
          setAzureAiServicesList(response.data.data.AzureAIServiceTopics)
          setMtcLocationList(response.data.data.MTCLocations)
          setJobTitleList(response.data.data.JobTitles)
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const getCitySuggestion = (value) => {
    cityString = value
    if(value && value!==""){
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.get(`https://dev.virtualearth.net/REST/v1/Autosuggest?query=${value}&key=${BaseConfig.apiKey}&includeEntityTypes=Address,Place&maxResults=10`, config)
      .then(response => {
        if (response.status) {
          let responseData = response.data.resourceSets[0].resources[0].value
          responseData = responseData.map(item => ({
            value: item.name ? `${item.name}, ${item.address.formattedAddress}` : item.address.formattedAddress,
            label: item.name ? `${item.name}, ${item.address.formattedAddress}` : item.address.formattedAddress
          }))
        if(value===cityString){
          setSuggestedCityList(responseData);
        }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
    }else{
      setSuggestedCityList([])
    }
  }

  const getGeoCoordinates = async (value) => {
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    try {
      const response = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?query=${value}&key=${BaseConfig.apiKey}`, config);

      if (response.status === 200) {
        let responseData = response.data.resourceSets[0].resources;
        responseData = responseData[0].point.coordinates;
        console.log("geocoordinate", responseData);
        return responseData;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const loginProcess = async () => {
      var successfulSignIn = false;
      try {
        if (accounts.length > 0) {
          const accessTokenRequest = {
            scopes: ["https://graph.microsoft.com/.default"],
            account: accounts ? accounts[0] : null,
          };
          const response = await instance.acquireTokenSilent(accessTokenRequest);
          sessionStorage.setItem("userName", response.account.name);
          sessionStorage.setItem("Emailid", response.account.username);
          sessionStorage.setItem("accountId", response.account.localAccountId);
          sessionStorage.setItem("userToken", response.accessToken);
          setIsSignedIn(true)
          successfulSignIn = true
        } else {
          throw Error("not found any current active account")
        }
      } catch (error) {
        sessionStorage.clear();
        window.location.replace('/');
      }
      if (successfulSignIn) {
        getDropdownVlaues();
        setShowProgress(false)
      }
    }
    loginProcess();
    // eslint-disable-next-line
  }, []);

  let initials = null;
  try {
    const userName = sessionStorage.getItem('userName');
    if (userName !== null) {
      // eslint-disable-next-line
      initials = userName?.split(' ')
        .map(word => word.charAt(0))
        .join('');
    }
  } catch (error) {
    // Handle the error, if needed
  }

  try {
    return (
      <>
        {isSignedIn &&
          <Box sx={{ display: 'flex' }}>
            <Navbar></Navbar>
            <MenubarfindSpeaker></MenubarfindSpeaker>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              {/* <div className="new-application-note" style={{ marginBottom: '30px' }}>
                <span>
                  Welcome to the new Engage AI Triage application! This is a Beta release. If you have any questions please reach out to{" "}
                  <a
                    href="mailto:engageai@microsoft.com"
                    title="mailto:engageai@microsoft.com"
                  >
                    engageai@microsoft.com
                  </a>
                </span>
              </div> */}
              {/* <Grid item xs={12} style={{ fontSize: '18px', marginBottom: '20px', fontFamily: 'Segoe UI Regular' }}><b> Welcome Back, {sessionStorage.getItem('userName')?.split(' ')[0]} </b></Grid> */}

              <Grid container style={{ border: '1.5px solid #ccc', borderRadius: '10px', marginTop: '45px' }} >
                <div style={{ display: 'flex', alignItems: 'center', height: '100%', backgroundColor: 'white', marginTop: '-12px', marginLeft: '20px', paddingLeft: '5px', paddingRight: '5px' }}>
                  <img
                    src={filterImg}
                    alt=''
                    style={{
                      width: '21px',
                      height: '21px',
                      marginRight: '7px'
                    }}
                  />
                  Filter/Search Options
                </div>
                <Grid xs={12}>
                  <Grid container paddingBottom={1} paddingTop={1}>
                    <Grid xs={5.8}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', paddingLeft: '20px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            isClearable
                            id="demo-select-small"
                            name="Enter City Name"
                            placeholder="Enter City Name"
                            styles={reactSelectCustomStyles}
                            noOptionsMessage={() => "Please enter a valid city name for suggestions."}
                            onMenuClose={()=>{setSuggestedCityList([])}}
                            isDisabled={Object.keys(searchedMTCLocation).length > 0}
                            options={suggestedCityList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            filterOption={() => true}
                            value={Object.keys(searchedCityName).length > 0 ? {
                              value: searchedCityName.value,
                              label: searchedCityName.label,
                            } : ''}
                            onInputChange={(e) =>{ 
                              getCitySuggestion(e)
                            }}
                            onChange={(selectedOptions) => {
                              setSearchedCityName({})
                              if (selectedOptions) {
                                setSearchedCityName(selectedOptions)
                              } else {
                                setSearchedCityName({})
                                sessionStorage.removeItem('CityName')
                              }
                            }
                            }
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid xs={0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography style={{ color: '#9a8e85' }}><b>OR</b></Typography>
                    </Grid>

                    <Grid xs={5.8}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '10px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            isClearable
                            id="demo-select-small"
                            name="Search MTC location"
                            placeholder="Search MTC location"
                            styles={reactSelectCustomStyles}
                            noOptionsMessage={() => "No more options"}
                            isDisabled={Object.keys(searchedCityName).length > 0}
                            options={mtcLocationList.map((item) => ({
                              value: item.Id,
                              label: item.MTCLocation,
                            }))}
                            value={Object.keys(searchedMTCLocation).length > 0 ? {
                              value: searchedMTCLocation.value,
                              label: searchedMTCLocation.label,
                            } : ''}
                            onChange={(selectedOptions) => {
                              setSearchedMtcLocation({})
                              if (selectedOptions) {
                                setSearchedMtcLocation(selectedOptions)
                              } else {
                                setSearchedMtcLocation({})
                                sessionStorage.removeItem('MTCLocation')
                              }
                            }
                            }
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container paddingBottom={1} >
                    <Grid xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            id="JobTitle"
                            name="JobTitle"
                            placeholder="Select Job Title(s)"
                            options={jobTitleList.map((option) => ({
                              value: option.Job_Title,
                              label: option.Job_Title,
                            }))}
                            closeMenuOnSelect={false}
                            onMenuClose={() => {
                              if (searchedJobTitle.length > 0 && menuItemChanged === true) {
                                setMenuItemChanged(false);
                              }
                              setMenuopen(false);
                            }}
                            onMenuOpen={() => {
                              setMenuopen(true)
                            }}
                            isMulti
                            value={searchedJobTitle}
                            styles={reactSelectCustomStyles}
                            noOptionsMessage={() => "No more options"}
                            onChange={(selectedOptions) => {
                              setSearchedJobTitle(selectedOptions);
                              setMenuItemChanged(true);
                              if (menuopen === false) {
                              }
                              if (selectedOptions && selectedOptions.length > 0) {
                                sessionStorage.setItem('JobTitle', JSON.stringify(selectedOptions));
                              } else {
                                sessionStorage.removeItem('JobTitle')
                              }
                            }}
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container style={{ border: '1.5px solid #ccc', borderRadius: '10px', marginTop: '20px', width: '97%', marginLeft: '20px', paddingBottom: '10px', marginBottom: '20px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', backgroundColor: 'white', marginTop: '-12px', marginLeft: '20px', paddingLeft: '5px', paddingRight: '5px' }}>
                      Topics
                    </div>
                    <Grid container paddingBottom={0}>
                      <Grid xs={6.8}>
                        <div style={{ paddingLeft: '20px' }}>
                          <Grid container alignItems="center">
                            <Grid item xs={4.5}>
                              <div >
                                <FormControlLabel
                                  control={
                                    <Checkbox name="EraofAIorL100AIServices"
                                      checked={eraOrL100} onChange={(event) => setEraOrL100(event.target.checked)}
                                    />
                                  }
                                  label={<span style={{ fontSize: '14px' }}>Era of AI or L100 AI Services</span>} />
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div >
                                <FormControlLabel
                                  control={
                                    <Checkbox name="AzureOpenAI"
                                      checked={azureOpenAI} onChange={(event) => setAzureOpenAI(event.target.checked)}
                                    />
                                  }
                                  label={<span style={{ fontSize: '14px' }}>Azure Open AI</span>} />
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div >
                                <FormControlLabel
                                  control={
                                    <Checkbox name="ResponsibleAI"
                                      checked={responsibleAI} onChange={(event) => setResponsibleAI(event.target.checked)}
                                    />
                                  }
                                  label={<span style={{ fontSize: '14px' }}>Responsible AI</span>} />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container paddingBottom={1}>
                      <Grid xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                          <FormControl sx={{ flexGrow: 1 }} size="small">
                            <Reactselect
                              id="AzureAIService"
                              name="AzureAIService"
                              placeholder="Select Azure AI Service Topic(s)"
                              options={AzureAiServicesList.map((option) => ({
                                value: option,
                                label: option,
                              }))}
                              closeMenuOnSelect={false}
                              onMenuClose={() => {
                                if (searchedAzureAiService.length > 0 && menuItemChanged === true) {
                                  setMenuItemChanged(false);
                                }
                                setMenuopen(false);
                              }}
                              onMenuOpen={() => {
                                setMenuopen(true)
                              }}
                              isMulti
                              value={searchedAzureAiService}
                              styles={reactSelectCustomStyles}
                              noOptionsMessage={() => "No more options"}
                              onChange={(selectedOptions) => {
                                setSearchedAzureAiService(selectedOptions);
                                setMenuItemChanged(true);
                                if (menuopen === false) {
                                }
                                if (selectedOptions && selectedOptions.length > 0) {
                                  sessionStorage.setItem('AzureAiService', JSON.stringify(selectedOptions));
                                } else {
                                  sessionStorage.removeItem('AzureAiService')
                                }
                              }}
                              classNamePrefix="react-select"
                            />
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>


                    <Grid container paddingBottom={1}>
                      <Grid xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                          <FormControl sx={{ flexGrow: 1 }} size="small">
                            <Reactselect
                              id="AzureMLTopic"
                              name="AzureMLTopic"
                              placeholder="Select Azure ML Topic(s)"
                              options={azureMLTopicList.map((option) => ({
                                value: option,
                                label: option,
                              }))}
                              closeMenuOnSelect={false}
                              onMenuClose={() => {
                                if (searchedAzureMLTopic.length > 0 && menuItemChanged === true) {
                                  setMenuItemChanged(false);
                                }
                                setMenuopen(false);
                              }}
                              onMenuOpen={() => {
                                setMenuopen(true)
                              }}
                              isMulti
                              value={searchedAzureMLTopic}
                              styles={reactSelectCustomStyles}
                              noOptionsMessage={() => "No more options"}
                              onChange={(selectedOptions) => {
                                setSearchedAzureMLTopic(selectedOptions);
                                setMenuItemChanged(true);
                                if (menuopen === false) {
                                }
                                if (selectedOptions && selectedOptions.length > 0) {
                                  sessionStorage.setItem('AzureMLTopic', JSON.stringify(selectedOptions));
                                } else {
                                  sessionStorage.removeItem('AzureMLTopic')
                                }
                              }}
                              classNamePrefix="react-select"
                            />
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container paddingBottom={1} paddingTop={2}>
                <Grid xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: '#2088ff', height: '40px', fontSize: '12px' }} onClick={handleSearchClick}>Search</Button>
                    <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: '#2088ff', height: '40px', fontSize: '12px', marginLeft: '20px' }} onClick={handleClearAllFilters}>Clear All</Button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '10px' }}>
                    <Typography style={{ color: '#9a8e85', fontSize: '10px' }}><b>Note: Search will be done within 100 miles radius</b></Typography>
                  </div>
                </Grid>
              </Grid>

              {speakerData.length > 0 && !tableLoader &&
                <>
                  <div style={{ marginTop: '20px' }}>
                    <TableComponent tableData={speakerData} selectAll={selectAll} setSelectAllChange={setSelectAllChange} handleSelectedIndex={handleSelectedIndex} selectedIndex={selectedIndex} tableLoader={tableLoader}></TableComponent>
                  </div>

                  <div style={{ display: 'flex', marginTop: '20px' }}>
                    <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: !(selectedIndex.length > 0) ? '#f6f6f6' : '#2088ff', height: '40px', fontSize: '12px', marginRight: '20px' }} disabled={!(selectedIndex.length > 0)} onClick={() => { triggerExport() }}>Export to Excel</Button>
                    <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: !(selectedIndex.length > 0) ? '#f6f6f6' : '#2088ff', height: '40px', fontSize: '12px' }} disabled={!(selectedIndex.length > 0)} onClick={() => { handleCopyToClipboardClick() }} >{clipboardClicked ? 'Copied EmailIDs to Clipboard' : 'Copy EmailIDs to Clipboard'}</Button>
                  </div>
                </>
              }

              {noResultFound && !tableLoader &&
                <div style={{ marginTop: '20px' }}>
                  <span >No speakers found for the selected criteria.</span>
                </div>
              }

              {tableLoader &&
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              }
            </Box>
            <Snackbar open={openRequiredfields} autoHideDuration={4000} onClose={() => { setOpenRequiredFields(false) }}>
              <Alert onClose={() => { setOpenRequiredFields(false) }} severity="error" sx={{ width: '100%' }}>
                Please enter either City Name or MTC Location
              </Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showProgress}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        }
      </>
    );
  } catch (error) {
    console.log("Going to error page", error)
    return <ExceptionHandled />;
  }
}