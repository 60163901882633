import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import {
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
import { Grid, Paper } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import BaseConfig from '../../config/config';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    display: 'flex', flexDirection: 'column',
    fontFamily: 'Segoe UI Regular'
};

const headerCell = {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Segoe UI Regular'
}

const contentCell = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    minWidth: '200px'
}

const detailsStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '13px',
}

function MailHistory(props) {
    // eslint-disable-next-line
    const { instance, inProgress, accounts } = useMsal();
    // eslint-disable-next-line
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const [selectedMail, setSelectedMail] = React.useState(null);
    const [mailHistoryDetails, setMailHistoryDetails] = React.useState([]);

    const handleRowClick = (rowId) => {
        setSelectedRowId(rowId);
        let TempSelectedMail = mailHistoryDetails.find(item => item.id === rowId)
        setSelectedMail(TempSelectedMail);
    };

    const getRequestMail = () => {
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.get(BaseConfig.apiBaseUrl + 'triage/request/mailHistory/' + sessionStorage.getItem('Emailid') + '/' + sessionStorage.getItem('requestId'), config)
            .then(response => {
                if (response.data.status) {
                    setMailHistoryDetails(response.data.data.value)
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        // if (sessionStorage.getItem('userName')) {
        //   getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
        // }
        // else {
        //   navigate('/');
        // }
        const loginProcess = async () => {
            var successfulSignIn = false;
            try {
                if (accounts.length > 0) {
                    const accessTokenRequest = {
                        scopes: ["https://graph.microsoft.com/.default"],
                        account: accounts ? accounts[0] : null,
                    };
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    sessionStorage.setItem("userName", response.account.name);
                    sessionStorage.setItem("Emailid", response.account.username);
                    sessionStorage.setItem("accountId", response.account.localAccountId);
                    sessionStorage.setItem("userToken", response.accessToken);
                    setIsSignedIn(true)
                    successfulSignIn = true
                } else {
                    throw Error("not found any current active account")
                }
            } catch (error) {
                sessionStorage.clear();
                window.location.replace('/');
            }
            if (successfulSignIn) {
                getRequestMail();
            }
        }
        loginProcess();
        // eslint-disable-next-line
    }, []);

    console.log(mailHistoryDetails);

    try {
        return (
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography id="modal-modal-title" component="h1" style={{ color: '#2D323A', marginBottom: '10px', fontSize: '17', fontFamily: 'Segoe UI Regular' }}>
                                <b>Mail History</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                                onClick={props.handleMailHistoryClose}
                            >
                                <Close
                                    style={{
                                        fontSize: 25,
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Divider
                        style={{ width: '100%', color: 'darkgray', marginBottom: '0.5rem' }}
                    />

                    <Typography id="modal-modal-title" style={{ color: '#2D323A', marginBottom: '10px', fontSize: '12px', fontFamily: 'Segoe UI Regular' }}>
                        Here's a list of all the emails sent through Engage AI Application for this request. Click on any email to view its details:
                    </Typography>
                    <div style={{ flex: '0 0 auto', marginBottom: '0.5rem', maxHeight: '130px', overflowY: 'scroll' }}>
                        <TableContainer component={Paper} style={{ maxHeight: '500px' }} >
                            <Table size='small'>
                                <TableHead >
                                    <TableRow style={{ backgroundColor: '#1976d2' }}>
                                        <TableCell style={headerCell}>Date/Time</TableCell>
                                        <TableCell style={headerCell}>Sender</TableCell>
                                        <TableCell style={headerCell}>To</TableCell>
                                        <TableCell style={headerCell}>CC</TableCell>
                                        <TableCell style={headerCell}>Subject</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mailHistoryDetails?.map((mail) => (
                                        <TableRow
                                            key={mail.id}
                                            onClick={() => handleRowClick(mail.id)}
                                            style={{ backgroundColor: selectedRowId === mail.id ? 'lightblue' : 'inherit', cursor: 'pointer' }}
                                        >
                                            <TableCell style={contentCell}>{moment.utc(mail?.fields['System.CreatedDate']).format('M/D/YYYY h:mm A') || ''}</TableCell>
                                            <TableCell style={contentCell}>{mail?.fields['Custom.From']?.replace(/;/g, ',')}</TableCell>
                                            <TableCell style={contentCell}>{mail?.fields['Custom.To']?.replace(/;/g, ',')}</TableCell>
                                            <TableCell style={contentCell}>{mail?.fields['Custom.CC']?.replace(/;/g, ',')}</TableCell>
                                            <TableCell style={contentCell}>{mail?.fields['Custom.Subject']}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <Grid container>
                        <Grid item xs={9}>
                            <Typography id="modal-modal-title" component="h1" style={{ color: '#2D323A', marginBottom: '10px', marginTop: '6px', fontSize: '15', fontFamily: 'Segoe UI Regular' }}>
                                <b>Mail Details</b>
                            </Typography>
                        </Grid>
                    </Grid>

                    <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
                        <Paper elevation={3} style={{ padding: '16px', fontFamily: 'Segoe UI Regular', border: '0.001px solid #c9c9c9' }}>
                            <Stack direction="column">
                                <Typography style={detailsStyle}><b>To:</b> {selectedMail?.fields['Custom.To']?.replace(/;/g, ',')}</Typography>
                                <Typography style={detailsStyle}><b>CC:</b> {selectedMail?.fields['Custom.CC']?.replace(/;/g, ',')}</Typography>
                                <Typography style={detailsStyle}><b>Subject:</b> {selectedMail?.fields['Custom.Subject']}</Typography>
                                <Typography style={detailsStyle}><b>Date/Time:</b> {selectedMail ? moment.utc(selectedMail?.fields['System.CreatedDate']).format('M/D/YYYY h:mm A') : ''}</Typography>
                                <Typography style={detailsStyle}>
                                    <b>Body:</b> <br />
                                    <div dangerouslySetInnerHTML={{ __html: selectedMail?.fields['Custom.Body'] }} />
                                </Typography>
                            </Stack>
                        </Paper>
                    </div>

                </Box>
            </Modal>
        )
    } catch (error) {
        return <ExceptionHandled />;
    }
}

export default MailHistory