import * as React from 'react';
import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import Navbar from '../components/Navbar';
import axios from 'axios';
import MenuBar from './menuBar';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import ContactsPng from './../../assets/Images/contacts.png';
import 'react-quill/dist/quill.snow.css';
import BaseConfig from '../../config/config';
import { useMsal } from '@azure/msal-react';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

const formatting = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=11">
  <title></title>
  <style>
    table,
    tr,
    td {
      border-collapse: collapse;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-position: inside;
      font-family: 'Segoe UI', Verdana, Geneva, sans-serif;
    }

    ul li {
      font-family: 'Segoe UI', Verdana, Geneva, sans-serif;
    }
    @media only screen and (max-width: 600px) {
      .column {
        width: 100% !important;
      }

      td[class=column2row] {
        display: block;
        width: 100% !important;
        padding-top: 20px;
      }

      td[class=column3row] {
        display: block;
        width: 100% !important;
        padding-top: 20px;
      }
      td[class=fullWidth] {
        width: 100% !important;
      }

      body {
        padding: 0 !important;
      }

      .fleximage {
        display: block;
        max-width: 100%;
        width: 100%;
        border: 0;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      td[class=center] {
        text-align: center !important;
      }

      .mobile-hide {
        display: none !important;
        line-height: 0 !important;
      }

      .mobile-share {
        display: block !important;
        width: auto !important;
        max-height: inherit !important;
        overflow: visible !important;
        float: none !important;
      }

      
      .fullWidth {
        width: 100% !important;
      }
    }
    
  </style>
</head>

<body link="#0078D4" vlink="#0078D4" alink="#0078D4"
  style="margin: 0; padding: 0; background-color: #E3E3E3; font-size: 10.5pt; font-family: Segoe UI, bodyfont; font-weight: normal; font-style: normal; line-height: normal; color: #000000;">
  <table role="presentation" width="100%" border="0" cellspacing="0" cellpadding="0"
    style="border: 0; border-collapse: collapse; width: 100%; background-color: #E3E3E3; font-size: 10.5pt; font-family: Segoe UI, bodyfont; font-weight: normal; font-style: normal; line-height: normal; color: #000000; text-align: left; mso-table-lspace: 0; mso-table-rspace: 0;">
    <tr>
      <td height="20"
        style="height:20px; font-size:0px; mso-line-height-rule:exactly; line-height:0px;"> </td>
    </tr>
    <tr>
      <td valign="top" align="center" style="border: 0;">
        <table role="presentation" border="0" cellspacing="0" cellpadding="0"
          style="border: 0; border-collapse: collapse; margin: 0 auto; border-collapse: collapse; background-color: #E6E6E6; font-size: 10.5pt; font-family:'Segoe UI', Verdana, Geneva, sans-serif; mso-table-lspace: 0; mso-table-rspace: 0;">

          <!-- row 1 -->
          <tr>
            <td width="600" align="center"
              style="border: 0; width: 600px; padding: 0; margin: 0; background-color: #FFFFFF;">
              <table role="presentation" width="100%" border="0" cellspacing="0" cellpadding="0"
                style="border: 0; border-collapse: collapse; width: 100%; padding: 0; margin: 0; background-color: #ffffff; font-size: 10.5pt; font-family: 'Segoe UI', Verdana, Geneva, sans-serif; font-weight: normal; font-style: normal; line-height: 0; color: #000000; text-align: left; mso-table-lspace: 0; mso-table-rspace: 0;">
                <tr>
                  <td valign="top" style="border: 0; padding: 0; margin: 0;">

                    <!-- column 1 -->
                    <table role="presentation" class="column" width="100%" border="0"
                      cellspacing="0" cellpadding="0"
                      style="border: 0; border-collapse: collapse; width: 100%; text-align: left; font-size: 10.5pt; font-family: 'Segoe UI', Verdana, Geneva, sans-serif; mso-table-rspace: 0; mso-table-lspace: 0; padding: 0; line-height: normal;">
                      <tr>
                        <td width="600" align="left" valign="top"
                          style="border: 0; width: 600px; background-color: #243a5e; margin: 0; padding: 20px 20px 40px 20px; color: #FFFFFF; border-collapse: collapse;">
                          <h1
                            style="font-family:'Segoe UI Semibold','Segoe UI', Verdana, Geneva, sans-serif; margin: 0px; padding:0px; font-weight: normal; mso-line-height: exactly; line-height: 150%; line-height: 35px;">
                            {{DynamicHeadingText}}
                          </h1>
                        </td>
                      </tr>
                      <tr>
                        <td width="600" valign="middle"
                          style="border: 0; width: 600px; height: 36px; vertical-align: middle; padding: 0 20px; margin: 0; background-color: #0078d4; font-size: 11.5pt; line-height: normal; font-family: 'Segoe UI Semibold', 'Segoe UI', Verdana, Geneva, sans-serif; color: #FFFFFF;">
                          Engage AI
                        </td>
                      </tr>
                      <tr>
                        <td width="600" valign="top"
                          style="border: 0; font-size: 10.5pt; font-family: 'Segoe UI', Verdana, Geneva, sans-serif; width: 100%; padding: 20px 20px 0 20px; margin: 0;">
                          <h2
                            style="font-family:'Segoe UI Semibold','Segoe UI', Verdana, Geneva, sans-serif; color:#0078d4; padding: 0 0 20px 0; margin: 0; font-weight: normal;">
                            {{DynamicSubheadingText}}
                          </h2>
                          </td>
                      </tr>
                        <tr>
                        <td width="600" valign="top"
                          <p style="font-family:'Segoe UI', Verdana, Geneva, sans-serif; margin: 20px 0 20px 0; text-align: left;">
                            {{EmailBody}}
                          </p>
                        </td>
                      </tr>
                    </table>
                    <!-- /column 1 -->

                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- /row 1 -->
          
          <!-- Add more rows here if needed -->
          
          <!-- Footer row -->
          <tr>
            <td width="600" align="center"
              style="border: 0; width: 600px; padding: 0; margin: 0; background-color: #FFFFFF; padding: 0;">
              <table role="presentation" width="100%" border="0" cellspacing="0" cellpadding="0"
                style="border: 0; border-collapse: collapse; width: 100%; padding: 0; margin: 0; background-color: #F2F2F2; font-family: 'Segoe UI', Verdana, Geneva, sans-serif; font-weight: normal; font-style: normal; color: #000000; text-align: left; mso-table-lspace: 0; mso-table-rspace: 0;">
                <tr>
                  <td width="600" align="center"
                    style="border: 0; width: 600px; padding: 0; margin: 0; background-color: #FFFFFF; padding: 0;">
                    <table role="presentation" width="100%" border="0" cellspacing="0"
                      cellpadding="0"
                      style="border: 0; border-collapse: collapse; width: 100%; padding: 0; margin: 0; background-color: #F2F2F2; font-family: 'Segoe UI', Verdana, Geneva, sans-serif; font-weight: normal; font-style: normal; color: #000000; text-align: left; mso-table-lspace: 0; mso-table-rspace: 0;">
                      <tr>
                        <td width="50%" valign="middle" align="left"
                          style="vertical-align:middle; text-align:left; padding: 20px 20px 20px 20px;">
                          <div style="width: 100px; overflow: hidden;">
                          <img border="0" width="120"
                          src="{{FooterImage}}"
                          alt="Microsoft AI footer logo"
                          style="display:block; border-width:0;" />
                        </div>
                        </td>
                        <td width="50%" valign="middle" align="right"
                          style='font-size:10.5pt;font-family:"Segoe UI",sans-serif; color:#000000; padding: 20px 20px 20px 20px;'>
                          <p style="margin:0;">Your front desk to an AI expert</p>
                          <p style="margin:0;">
                            <a href="https://aka.ms/EngageAI" target="_blank"
                              style="text-decoration:underline;">aka.ms/EngageAI</a>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- /Footer row -->
        </table>
      </td>
    </tr>
    <tr>
      <td height="20"
        style="height:20px; font-size:0px; mso-line-height-rule:exactly; line-height:0px;"> </td>
    </tr>
  </table>
</body>

</html>
`


const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 970,
    height: 420,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 1,
};

const buttonStyle = {
    fontSize: '10px',
    height: '30px'
};



export default function BlankMail() {
    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const { instance, inProgress, accounts } = useMsal();
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [emailBody, setEmailBody] = React.useState('')
    const [to, setTo] = React.useState([]);
    const [CC, setCC] = React.useState([]);
    const [urgent, setUrgent] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [SendingAsEngage, setSendingAsEngage] = React.useState(false);
    const [showProgress, setShowProgress] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [open200, setOpen200] = React.useState(false);
    const [buttonClicked, setButtonClicked] = React.useState(false);
    const [openRequiredfields, setOpenRequiredFields] = React.useState(false);

    const getEmailAddresses = (addresses) => {
        if (!addresses || addresses?.length === 0) {
            return '';
        }

        const allEmails = addresses
            .flatMap(innerArray => innerArray.map(item => item.mail))
            .filter(email => email?.length > 0);

        return allEmails.join(';');
    }

    const handleRequiredFields = () => {
        setButtonClicked(true);
        if (to?.length > 0 && subject !== '' && emailBody !== '') {
            sendMail();
        }
        else {
            setOpenRequiredFields(true)
        }
    };

    const addValue = async (value, name) => {
        if (to !== null) {
            setTo((prevValues) => {
                if (!prevValues.some((item) => item[0]?.mail === value)) {
                    return [...prevValues, [{ 'displayName': name, 'mail': value }]];
                }
                return prevValues;
            });
        }
        else {
            setTo([{ 'displayName': name, 'mail': value }]);
        }
    };

    const handleToChange = (event, value) => {
        if (to !== null) {
            setTo((prevValues) => {
                if (!prevValues.some((item) => item === value)) {
                    return [...prevValues, value];
                }
                return prevValues;
            });
        }
        else {
            setTo(value);
        }
    };

    const handleCCChange = (event, value) => {
        if (CC !== null) {
            setCC((prevValues) => {
                if (!prevValues.some((item) => item === value)) {
                    return [...prevValues, value];
                }
                return prevValues;
            });
        }
        else {
            setCC(value);
        }
    };

    const handleUrgentCheck = (event) => {
        setUrgent(event.target.checked);
    };

    const handleButtonClick = (mail, name) => {
        addValue(mail, name);
    };

    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);

    const onEditorStateChange = (editorState) => {
        if (editorState.replace(/<[^>]+>/g, '')?.trim() === '') {
            editorState = ''
        }
        setEmailBody(editorState);
    };

    const fetchData = async (value) => {
        var successfulSignIn = false;
        try {
            if (accounts?.length > 0) {
                const accessTokenRequest = {
                    scopes: ["User.Read","openid", "profile", "offline_access","email","User.ReadBasic.All"],
                    account: accounts ? accounts[0] : null,
                };
                const response = await instance.acquireTokenSilent(accessTokenRequest);
                sessionStorage.setItem("userName", response.account.name);
                sessionStorage.setItem("Emailid", response.account.username);
                sessionStorage.setItem("accountId", response.account.localAccountId);
                sessionStorage.setItem("userToken", response.accessToken);
                setIsSignedIn(true)
                successfulSignIn = true
            } else {
                throw Error("not found any current active account")
            }
        } catch (error) {
            sessionStorage.clear();
            window.location.replace('/');
        }
        if (successfulSignIn) {
            const url =
                `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'@microsoft.com') and (startsWith(displayName,'${value}') or startsWith(mail,'${value}'))`;
            const headers = {
                ConsistencyLevel: "eventual",
                Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
                "Content-Type": "application/json",
            };
            try {
                const response = await axios.get(url, { headers });
                setOptions(response.data.value)
                return response.data.value;
            } catch (error) {
                console.error(error);
                setOptions([]);
                return []
            }
        }
    };

    // const getCardDetailsById = async () => {
    //     const formData = new FormData();
    //     formData.append("user_id", sessionStorage.getItem('userName'));
    //     formData.append("id", sessionStorage.getItem('requestId'));
    //     let didReceiveResponse = false;
    //     try {
    //         const url = BaseConfig.apiBaseUrl + 'triage/getRequestInfoById';
    //         const responsePromise = axios.post(url, formData, {
    //             headers: {
    //                 "Content-Type": undefined,
    //             },
    //         });
    //         const timeoutPromise = new Promise((resolve, reject) => {
    //             setTimeout(() => {
    //                 reject(new Error("Request timed out"));
    //             }, 4000);
    //         });
    //         const response = await Promise.race([responsePromise, timeoutPromise]);
    //         if (response.data.status === "200") {
    //             setCardInfo(response.data.data.value[0]);
    //             getTemplate();
    //             didReceiveResponse = true;
    //         }
    //     } catch (error) {
    //         console.error("Failed to create request", error);
    //     }
    //     if (!didReceiveResponse) {
    //         setCardInfo([]);
    //     }
    // };

    const handleRemoveValue = (value) => {
        const updatedSelectedValues = to.filter((item) => item !== value);
        setTo(updatedSelectedValues);
    };

    const handleRemoveCCValue = (value) => {
        const updatedSelectedValues = CC.filter((item) => item !== value);
        setCC(updatedSelectedValues);
    };

    const sendMail = () => {
        const formData = new FormData();

        let EmailBody = formatting;
        EmailBody = EmailBody
            .replace(/{{EmailBody}}/g, emailBody)
            .replace(/{{DynamicHeadingText}}/g, 'Regarding your request')
            .replace(/{{FooterImage}}/g, BaseConfig.apiBaseUrl + 'public/assests/Microsoft.png')
            .replace(/{{DynamicSubheadingText}}/g, 'Regarding your request');

        formData.append("title", sessionStorage.getItem('requestId') + "- TriageMail");
        formData.append("subject", subject);
        formData.append("to", getEmailAddresses(to));
        formData.append("CC", getEmailAddresses(CC));
        formData.append("body", EmailBody?.replace(/<p><br><\/p>/g, ''));
        formData.append("request_id", sessionStorage.getItem('requestId'));
        formData.append("from", SendingAsEngage ? sessionStorage.getItem('Emailid') : 'engageai@microsoft.com');
        formData.append("importance", urgent ? 'high' : '');

        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        try {
            axios.post(BaseConfig.apiBaseUrl + 'request/sendEmail', formData, config)
                .then(response => {
                    if (response.status === 200) {
                        setOpen200(true);
                        const delay = setTimeout(() => {
                            navigate('/technicalcontenthome')
                        }, 700);
                        console.log(delay);
                    }
                    else {

                    }
                })
                .catch(function (error) {

                });
        }
        catch (error) {

        }
    }

    const getTemplate = async () => {
        let requestType = "Default template for all other conditions";

        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        try {
            axios.get(BaseConfig.apiBaseUrl + 'triage/getMailTemplate/' + sessionStorage.getItem('Emailid') + '/' + requestType, config)
                .then(response => {
                    if (response.status === 200) {

                        var EmailBody = response.data.data.value[0].fields['Custom.EmailBody'].replace(/{{To}}/g, '');

                        setEmailBody(EmailBody);
                        setShowProgress(false);
                    }
                    else {

                    }
                })
                .catch(function (error) {

                });
        }
        catch (error) {

        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const loginProcess = async () => {
            var successfulSignIn = false;
            try {
                if (accounts?.length > 0) {
                    const accessTokenRequest = {
                        scopes: ["https://graph.microsoft.com/.default"],
                        account: accounts ? accounts[0] : null,
                    };
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    sessionStorage.setItem("userName", response.account.name);
                    sessionStorage.setItem("Emailid", response.account.username);
                    sessionStorage.setItem("accountId", response.account.localAccountId);
                    sessionStorage.setItem("userToken", response.accessToken);
                    setIsSignedIn(true)
                    successfulSignIn = true
                } else {
                    throw Error("not found any current active account")
                }
            } catch (error) {
                sessionStorage.clear();
                window.location.replace('/');
            }
            if (successfulSignIn) {
                getTemplate();
            }
        }
        loginProcess();
        // eslint-disable-next-line
    }, []);

    try {
        return (
            <>
                {isSignedIn &&
                    <>
                        <Navbar portalName={"- Engagement Requests"}></Navbar>
                        <MenuBar sendMail={handleRequiredFields} ></MenuBar>
                        <div style={{ paddingTop: '110px', paddingLeft: '40px', paddingRight: '40px' }}>
                            <div style={{ fontFamily: 'Segoe UI Regular' }}>
                                {/* <Grid item xs={12} style={{ fontSize: '18px', marginBottom: '20px' }}><b> Welcome Back, {sessionStorage.getItem('userName').split(' ')[0]} </b></Grid> */}
                                <Grid item xs={12} >
                                    {/* <FormControlLabel
                                    style={{ fontFamily: 'Segoe UI Regular', marginRight: '30px' }}
                                    control={<Checkbox color="primary" checked={SendingAsEngage} onChange={(event) => { setSendingAsEngage(event.target.checked) }} />}
                                    label={SendingAsEngage ? `Sending as: ${sessionStorage.getItem('userName')}` : "Sending as: EngageAI"}
                                    labelPlacement="end"
                                /> */}

                                    <FormControlLabel
                                        style={{ fontFamily: 'Segoe UI Regular', marginRight: '30px' }}
                                        control={<Typography >
                                            Sending as: EngageAI
                                        </Typography>}
                                    />

                                    <FormControlLabel
                                        style={{ fontFamily: 'Segoe UI Regular', marginRight: '30px' }}
                                        control={<Checkbox color="primary" checked={urgent} onChange={handleUrgentCheck} />}
                                        label="Urgent"
                                        labelPlacement="end"
                                    />

                                </Grid>

                                <Grid item xs={12} style={{ fontSize: '18', marginTop: '10px', marginBottom: '5px' }}><b> To </b></Grid>
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Autocomplete
                                                freeSolo
                                                multiple
                                                id="tags-outlined"
                                                size="small"
                                                options={options}
                                                getOptionLabel={(option) => option?.displayName && option?.mail}
                                                filterOptions={(options, { inputValue }) => {
                                                    const searchTerms = inputValue.trim().split(' ');
                                                    return options.filter((option) =>
                                                        searchTerms.every(
                                                            (term) =>
                                                                option?.displayName.toLowerCase().includes(term.toLowerCase()) ||
                                                                option?.mail.toLowerCase().includes(term.toLowerCase())
                                                        )
                                                    );
                                                }}
                                                onInputChange={(event, value) => fetchData(value)}
                                                onChange={handleToChange}
                                                value={[]}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label=""
                                                        required
                                                        sx={{ minWidth: '1100px' }}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <>
                                                        <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                        <div style={{ marginTop: '0.5rem' }}>
                                                            <li {...props} style={{ marginBottom: '10' }}>
                                                                <div>
                                                                    <b><span style={{ fontSize: 16 }}>{option.displayName}</span></b>
                                                                    <br />
                                                                    <span style={{ fontSize: 11, color: 'grey' }}>{option.mail}</span>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={handleModalOpen}>
                                                    <img
                                                        src={ContactsPng}
                                                        alt='chatbot'
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>

                                            <InputAdornment position="end" >
                                                <IconButton edge="end" onClick={() => { setTo([]) }} >
                                                    <Delete style={{ color: '#1976d2' }}></Delete>
                                                </IconButton>
                                            </InputAdornment>
                                        </div>

                                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                            {to.map((value, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        backgroundColor: '#1976d2',
                                                        color: 'white',
                                                        padding: '0.5rem',
                                                        margin: '0.5rem',
                                                        borderRadius: '4px',
                                                        fontSize: '11px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {value[0]?.displayName}
                                                    <Close
                                                        onClick={() => handleRemoveValue(value)}
                                                        style={{ cursor: 'pointer', marginLeft: '0.5rem', fontSize: '11px' }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </Grid>


                                <Grid item xs={12} style={{ fontSize: '18', marginTop: '10px', marginBottom: '5px' }}><b> CC </b></Grid>
                                <Grid item xs={12}>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Autocomplete
                                                freeSolo
                                                multiple
                                                id="tags-outlined"
                                                size="small"
                                                options={options}
                                                getOptionLabel={(option) => option?.displayName && option?.mail}
                                                filterOptions={(options, { inputValue }) => {
                                                    const searchTerms = inputValue.trim().split(' ');
                                                    return options.filter((option) =>
                                                        searchTerms.every(
                                                            (term) =>
                                                                option?.displayName.toLowerCase().includes(term.toLowerCase()) ||
                                                                option?.mail.toLowerCase().includes(term.toLowerCase())
                                                        )
                                                    );
                                                }}
                                                onInputChange={(event, value) => fetchData(value)}
                                                onChange={handleCCChange}
                                                value={[]}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label=""
                                                        required
                                                        sx={{ minWidth: '1100px' }}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <>
                                                        <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                        <div style={{ marginTop: '0.5rem' }}>
                                                            <li {...props} style={{ marginBottom: '10' }}>
                                                                <div>
                                                                    <b><span style={{ fontSize: 16 }}>{option.displayName}</span></b>
                                                                    <br />
                                                                    <span style={{ fontSize: 11, color: 'grey' }}>{option.mail}</span>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <InputAdornment position="end" >
                                                <IconButton edge="end" onClick={() => { setCC([]) }} >
                                                    <Delete style={{ color: '#1976d2' }}></Delete>
                                                </IconButton>
                                            </InputAdornment>
                                        </div>

                                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                            {CC.map((value, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        backgroundColor: '#1976d2',
                                                        color: 'white',
                                                        padding: '0.5rem',
                                                        margin: '0.5rem',
                                                        borderRadius: '4px',
                                                        fontSize: '11px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {value[0]?.displayName}
                                                    <Close
                                                        onClick={() => handleRemoveCCValue(value)}
                                                        style={{ cursor: 'pointer', marginLeft: '0.5rem', fontSize: '11px' }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} style={{ fontSize: '18', marginTop: '10px', marginBottom: '5px' }}><b> Subject </b></Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        label=""
                                        error={buttonClicked && subject === ''}
                                        helperText={buttonClicked && subject === '' ? '*Required' : ''}
                                        required
                                        value={subject}
                                        onChange={(event) => { setSubject(event.target.value) }}
                                        sx={{ minWidth: '1100px' }}
                                    />
                                </Grid>

                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <ReactQuill
                                    theme="snow"
                                    style={{ height: '1000px' }}
                                    value={emailBody}
                                    onChange={(editorState) => { onEditorStateChange(editorState) }}
                                />
                            </div>

                        </div >
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showProgress}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Snackbar open={open200} autoHideDuration={4000} onClose={() => { setOpen200(false) }}>
                            <Alert onClose={() => { setOpen200(false) }} severity="success" sx={{ width: '100%' }}>
                                Mail Sent Successfully
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openRequiredfields} autoHideDuration={4000} onClose={() => { setOpenRequiredFields(false) }}>
                            <Alert onClose={() => { setOpenRequiredFields(false) }} severity="error" sx={{ width: '100%' }}>
                                TO or SUBJECT or BODY Field cannot be empty
                            </Alert>
                        </Snackbar>
                        <Modal
                            open={openModal}
                            onClose={handleModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleModalClose}
                                    sx={{ position: 'absolute', top: '0px', right: '8px' }}
                                >
                                    <Close />
                                </IconButton>

                                <Grid container spacing={2} padding={2}>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            DGs
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Directs
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            PMs
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Ambassadors
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('aoaispeakers@service.microsoft.com', 'Azure OpenAI') }} >Azure OpenAI</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('jmdir@microsoft.com', 'John Directs') }} >John Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('aace@microsoft.com', 'ACE Team') }} >ACE Team</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('visionpmall@microsoft.com', 'Vision PMs') }} >Vision PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('DP-AIAmbassadors@microsoft.com', 'WW AIA') }} >WW AIA</Button>
                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('azureaispeakers@service.microsoft.com', 'Azure AI') }}>Azure AI</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('ebftes@microsoft.com', 'Erez Directs') }} >Erez Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('amlpm@microsoft.com', 'AML PM') }} >AML PM</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('speechpmall@microsoft.com', 'Speech PMs') }} >Speech PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('appliedaispeakers@service.microsoft.com', 'Applied AI') }} >Applied AI</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('ChrisLaurensDirects@service.microsoft.com', 'Chris Directs') }} >Chris Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('ai-azureml-pm@microsoft.com', 'ML Platform') }} >ML Platform</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('languagepm@microsoft.com', 'Language PMs') }} >Language PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('cognitiveservicesspeakers@service.microsoft.com', 'Cognitive Services') }} >Cognitive Services</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('venkyvd@microsoft.com', 'Venky Directs') }} >Venky Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('mllifecyclepm@service.microsoft.com', 'ML Lifecycle') }} >ML Lifecycle</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('decisionlt@microsoft.com', 'Decision PMs') }} >Decision PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('azuremlspeakers@service.microsoft.com', 'Azure ML') }} >Azure ML</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('lilichdir@microsoft.com', 'Lili Directs') }} >Lili Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('CSPlatformPM@microsoft.com', 'CS Platform') }} >CS Platform</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('azureopenaiteam@microsoft.com', 'OpenAI Team') }} >OpenAI Team</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('responsibleaispeakers@service.microsoft.com', 'Responsible AI') }} >Responsible AI</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('alidrs@microsoft.com', 'Ali Directs') }} >Ali Directs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('responsibleaipm@microsoft.com', 'RAI PMs') }} >RAI PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('aibuilderpm@microsoft.com', 'AI Builder PMs') }} >AI Builder PMs</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('kmspeakers@service.microsoft.com', 'Cognitive Search') }} >Cognitive Search</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" color="primary" size="small" style={buttonStyle} onClick={() => { handleButtonClick('AIPSC@service.microsoft.com', 'AIPSC') }} >AIPSC</Button>
                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={1}>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Modal>
                    </>
                }
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}