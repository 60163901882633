import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField } from '@mui/material';
import Reactselect from 'react-select';
import Navbar from '../components/Navbar';
import TimezoneSelect from 'react-timezone-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link as MUILink } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import MenubarForm from './menubarEdit';
import BaseConfig from '../../config/config';
import moment from 'moment';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

const drawerWidth = 420;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '40px',
        height: '40px',
    }),


    valueContainer: (provided, state) => ({
        ...provided,
        height: '45px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '45px',
    }),
};

const statuslist = [
    { value: 'Content Requested', label: 'Content Requested' },
    { value: 'In Triage', label: 'In Triage' },
    { value: 'In Production', label: 'In Production' },
    { value: 'Request Declined', label: 'Request Declined' },
    { value: 'Completed/Published', label: 'Completed/Published' },
];

const contentAssetList = [
    { label: 'AI Show', value: 'AI Show' },
    { label: 'Demo', value: 'Demo' },
    { label: 'AI YouTube Channel Video Submission', value: 'AI YouTube Channel Video Submission' },
    { label: 'Blog Post', value: 'Blog Post' },
    { label: 'Field Briefing', value: 'Field Briefing' },
    { label: 'Technical Consult', value: 'Technical Consult' },
    { label: 'Skilling Session', value: 'Skilling Session' },
    { label: 'Technical Documentation', value: 'Technical Documentation' },
    { label: 'Technical Workshop', value: 'Technical Workshop' },
];

export default function ContentEdit() {
    const navigate = useNavigate()
    const { instance, inProgress, accounts } = useMsal();
    const [isSignedIn, setIsSignedIn] = React.useState(false);

    const [statusSelected, setStatusSelected] = React.useState('');
    const [assetType, setAssetType] = React.useState(null);
    const [title, setTitle] = React.useState(null);
    const [timeZone, setTimeZone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [businessArea, setBusinessArea] = React.useState(null);
    const [loader, setLoader] = React.useState(false);
    const [focusedProduct, setFocusedProduct] = React.useState(null);
    const [contentOwnerName, setContentOwnerName] = React.useState([]);
    const [contentOwnerNameOptions, setContentOwnerNameOptions] = React.useState([]);
    const [assignedPMM, setAssignedPMM] = React.useState([]);
    const [assignedPMMOptions, setAssignedPMMOptions] = React.useState([]);
    const [presenter, setPresenter] = React.useState([]);
    const [presenterOptions, setPresenterOptions] = React.useState([]);
    const [summary, setSummary] = React.useState(null);
    const [problem, setProblem] = React.useState(null);
    const [desiredOutcome, setDesiredOutcome] = React.useState(null);
    const [authorOrpresenterShortBio, setAuthorOrpresenterShortBio] = React.useState(null);
    const [authorOrpresenterSocialLinks, setAuthorOrpresenterSocialLinks] = React.useState(null);
    const [nonMicrosoftPresenter, setNonMicrosoftPresenter] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [confirmationDelete, setConfirmationDelete] = React.useState(false);
    const [authorOrPresenterHeadshotAttachment, setAuthorOrPresenterHeadshotAttachment] = React.useState(null);
    const [authorOrPresenterHeadshotAttachmentName, setAuthorOrPresenterHeadshotAttachmentName] = React.useState(null);
    const [updatedAuthorOrPresenterHeadshotAttachment, setUpdatedAuthorOrPresenterHeadshotAttachment] = React.useState(false);
    const [additionalDocsOrGraphicsAttachment, setAdditionalDocsOrGraphicsAttachment] = React.useState(null);
    const [additionalDocsOrGraphicsAttachmentName, setAdditionalDocsOrGraphicsAttachmentName] = React.useState(null);
    const [updatedAdditionalDocsOrGraphicsAttachment, setUpdatedAdditionalDocsOrGraphicsAttachment] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [cardInfo, setCardInfo] = React.useState(null);
    const [segment, setSegment] = React.useState(null);
    const [metadata, setMetaData] = React.useState(null);
    const [audience, setAudience] = React.useState(null);
    const [resources, setResources] = React.useState(null);
    const [extraInfo, setExtraInfo] = React.useState(null);
    const [contentAssetDetails, setContentAssetDetails] = React.useState(null);
    const [marketingCampaign, setMarketingCampaign] = React.useState(null);
    const [comments, setComments] = React.useState(null);
    const [previousComments, setPreviousComments] = React.useState('');

    const [open200, setOpen200] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openRequiredfields, setOpenRequiredFields] = React.useState(false)
    const [buttonClicked, setButtonClicked] = React.useState(false)

    const handleFileChange = (eventTrigger, event) => {
        try {
            const file = event.target.files[0];
            if (eventTrigger === "authorOrPresenterHeadshot") {
                setAuthorOrPresenterHeadshotAttachmentName(file.name);
                setAuthorOrPresenterHeadshotAttachment(file);
                setUpdatedAuthorOrPresenterHeadshotAttachment(true);
            } else {
                setAdditionalDocsOrGraphicsAttachmentName(file.name);
                setAdditionalDocsOrGraphicsAttachment(file);
                setUpdatedAdditionalDocsOrGraphicsAttachment(true);
            }
        }
        catch {

        }
    };

    const handleDrop = (eventTrigger, acceptedFiles) => {
        const file = acceptedFiles[0];
        if (eventTrigger === "authorOrPresenterHeadshot") {
            setAuthorOrPresenterHeadshotAttachmentName(file.name);
            setAuthorOrPresenterHeadshotAttachment(file);
            setUpdatedAuthorOrPresenterHeadshotAttachment(true);
        } else {
            setAdditionalDocsOrGraphicsAttachmentName(file.name);
            setAdditionalDocsOrGraphicsAttachment(file);
            setUpdatedAdditionalDocsOrGraphicsAttachment(true);
        }
    };

    const FileDropzone = ({ onDrop, children }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

        return (
            <div {...getRootProps()} className={`dropzone ${isDragActive && 'active'}`}>
                <input {...getInputProps()} />
                {children}
            </div>
        );
    };

    const handleRequestDelete = () => {
        var requestId = cardInfo ? cardInfo[0].fields['Custom.RequestID'] : ''
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.delete(BaseConfig.apiBaseUrl + 'triage/deleteRequest/' + sessionStorage.getItem('Emailid') + '/' + sessionStorage.getItem('requestId') + '/' + requestId, config)
            .then(response => {
                if (response.data.status) {
                    navigate('/technicalcontenthome')
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const OpenConfirmationPopup = () => {
        setConfirmationDelete(true)
    };

    const onEditorStateChange = (label, editorState) => {
        if (editorState.replace(/<[^>]+>/g, '')?.trim() === '') {
            editorState = ''
        }
        if (label === 'summary') {
            setSummary(editorState)
        }
        if (label === 'problem') {
            setProblem(editorState)
        }
        if (label === 'desiredOutcome') {
            setDesiredOutcome(editorState)
        }
        if (label === 'extraInfo') {
            setExtraInfo(editorState)
        }
        if (label === 'contentAssetDetails') {
            setContentAssetDetails(editorState)
        }
        if (label === 'resources') {
            setResources(editorState)
        }
        if (label === 'comments') {
            setComments(editorState)
        }
        if (label === 'authorOrpresenterShortBio') {
            setAuthorOrpresenterShortBio(editorState)
        }
        if (label === 'authorOrpresenterSocialLinks') {
            setAuthorOrpresenterSocialLinks(editorState)
        }
    };

    const getCardDetailsById = async () => {
        const formData = new FormData();
        formData.append("user_id", sessionStorage.getItem('Emailid'));
        formData.append("id", sessionStorage.getItem('requestId'));
        let didReceiveResponse = false; // Flag to check if a response was received
        try {
            const url = BaseConfig.apiBaseUrl + 'triage/getRequestInfoById';
            const responsePromise = axios.post(url, formData, {
                headers: {
                    "Content-Type": undefined,
                },
            });
            const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error("Request timed out"));
                }, 4000);
            });
            const response = await Promise.race([responsePromise, timeoutPromise]);
            if (response.data.status === "200") {
                setCardInfo(response.data.data.value);
                setStatusSelected(response.data.data.value[0].fields['Custom.TechnicalContentStatus'] || '');
                setAssetType(response.data.data.value[0].fields['Custom.ContentAssetType'] || '');
                setTitle(response.data.data.value[0].fields['Custom.ContentTitle'] || '');
                setTimeZone(response.data.data.value[0].fields['Custom.PresenterTimeZone'] || '');
                setLocation(response.data.data.value[0].fields['Custom.PresenterLocation'] || '');
                setFromDate(response.data.data.value[0].fields ? response.data.data.value[0].fields["Custom.PublicationDate"]?.length > 3 ? moment(response.data.data.value[0].fields["Custom.PublicationDate"]).format("YYYY-MM-DD") : "" : "");
                setToDate(response.data.data.value[0].fields ? response.data.data.value[0].fields["Custom.ExpirationDate"]?.length > 3 ? moment(response.data.data.value[0].fields["Custom.ExpirationDate"]).format("YYYY-MM-DD") : "" : "",);
                setBusinessArea(response.data.data.value[0].fields['Custom.BusinessArea'] || '')
                setFocusedProduct(response.data.data.value[0].fields['Custom.ProductOrServiceName'] || '');
                setSummary(response.data.data.value[0].fields['Custom.DescriptionOrExecutiveSummary'] || '');
                setProblem(response.data.data.value[0].fields['Custom.BusinessOrUseCase'] || '');
                setDesiredOutcome(response.data.data.value[0].fields['Custom.DesiredOutcome'] || '');
                setNonMicrosoftPresenter(response.data.data.value[0].fields['Custom.NameOfNonMicrosoftPresenter'] || '');
                setSegment(response.data.data.value[0].fields['Custom.SegmentLength'] || '');
                setAuthorOrpresenterShortBio(response.data.data.value[0].fields['Custom.AuthorOrpresenterShortBio'] || '');
                setAuthorOrpresenterSocialLinks(response.data.data.value[0].fields['Custom.AuthorOrpresenterSocialLinks'] || '');
                setMetaData(response.data.data.value[0].fields['Custom.MetadataKeywords'] || '');
                setAudience(response.data.data.value[0].fields['Custom.TypeOfAudience'] || '');
                setResources(response.data.data.value[0].fields['Custom.ResourceLinks'] || '');
                setExtraInfo(response.data.data.value[0].fields['Custom.MiscNotes'] || '');
                setAuthorOrPresenterHeadshotAttachmentName(response.data.data.value[0].fields['Custom.AuthorOrPresenterHeadshotAttachmentName'] || '');
                setAdditionalDocsOrGraphicsAttachmentName(response.data.data.value[0].fields['Custom.AdditionalDocsOrGraphicsAttachmentName'] || '');
                setContentAssetDetails(response.data.data.value[0].fields['Custom.ContentAssetDetails'] || '');
                setPreviousComments(response.data.data.value[0].fields['Custom.Comment'] || '');
                setMarketingCampaign(response.data.data.value[0].fields['Custom.MarketingCampaignOrUpcomingLaunch'] || '' ? 'Yes' : 'No');
                setContentOwnerName(response.data.data.value[0].fields ? response.data.data.value[0].fields["Custom.NameOfContentOwner"] ? [{
                    value: response.data.data.value[0].fields["Custom.NameOfContentOwner"] + " | " + response.data.data.value[0].fields["Custom.ContentOwnerEmail"],
                    displayName: response.data.data.value[0].fields["Custom.NameOfContentOwner"] || "",
                    mail: response.data.data.value[0].fields["Custom.ContentOwnerEmail"] || "",
                    localId: response.data.data.value[0].fields["Custom.ContentOwnerID"] || "",
                    label: response.data.data.value[0].fields["Custom.NameOfContentOwner"]
                }] : [] : [])
                setAssignedPMM(response.data.data.value[0].fields ? response.data.data.value[0].fields["Custom.PPMAssignedToName"] ? [{
                    value: response.data.data.value[0].fields["Custom.PPMAssignedToName"] + " | " + response.data.data.value[0].fields["Custom.PPMAssignedToEmail"],
                    displayName: response.data.data.value[0].fields["Custom.PPMAssignedToName"] || "",
                    mail: response.data.data.value[0].fields["Custom.PPMAssignedToEmail"] || "",
                    localId: response.data.data.value[0].fields["Custom.PPMAssignedToID"] || "",
                    label: response.data.data.value[0].fields["Custom.PPMAssignedToName"]
                }] : [] : [])
                setPresenter(response.data.data.value[0].fields ? response.data.data.value[0].fields["Custom.MicrosoftPresenterName"] ? [
                    {
                        value: response.data.data.value[0].fields["Custom.MicrosoftPresenterName"] + " | " + response.data.data.value[0].fields["Custom.MicrosoftPresenterEmail"],
                        displayName: response.data.data.value[0].fields["Custom.MicrosoftPresenterName"] || "",
                        mail: response.data.data.value[0].fields["Custom.MicrosoftPresenterEmail"] || "",
                        localId: response.data.data.value[0].fields["Custom.MicrosoftPresenterID"] || "",
                        label: response.data.data.value[0].fields["Custom.MicrosoftPresenterName"]
                    }
                ] : [] : [])
                didReceiveResponse = true;
            }
        } catch (error) {
            console.error("Failed to create request", error);
        }
        if (!didReceiveResponse) {
            setCardInfo([]);
        }
    };

    const update = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append("user_name", sessionStorage.getItem('userName'));
        formData.append("request_id", sessionStorage.getItem('requestId'));
        if (comments) {
            let updatedComments
            const currentDate = new Date().toLocaleString(undefined, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            if (previousComments === '' || !previousComments) {
                const formattedComments = comments.replace(/<p>/g, '').replace(/<\/p>/g, ' ').replace(/<br>/g, '').replace(/<p><br><\/p>/g, ''); // Remove HTML tags
                updatedComments = `[${currentDate}] ${sessionStorage.getItem('userName')}: ${formattedComments}`;
            }
            else {
                const formattedComments = comments.replace(/<p>/g, '').replace(/<\/p>/g, ' ').replace(/<br>/g, '').replace(/<p><br><\/p>/g, ''); // Remove HTML tags
                updatedComments = `[${currentDate}] ${sessionStorage.getItem('userName')}: ${formattedComments}<br>${previousComments}`;
            }
            formData.append("comments", updatedComments);
        }
        formData.append("title", title);
        formData.append("status", statusSelected);
        formData.append("contentAssetType", assetType);
        formData.append("presenterLocation", location);
        formData.append("presenterTimeZone", timeZone);
        if (authorOrPresenterHeadshotAttachment) {
            formData.append("authorOrPresenterHeadshotAttachment", authorOrPresenterHeadshotAttachment);
        }
        if (additionalDocsOrGraphicsAttachment) {
            formData.append("additionalDocsOrGraphicsAttachment", additionalDocsOrGraphicsAttachment);
        }
        if (fromDate) {
            formData.append("publicationDate", fromDate);
        }
        if (toDate) {
            formData.append("ExpirationDate", toDate);
        }
        formData.append("businessArea", businessArea);
        formData.append("productOrServiceName", focusedProduct);
        formData.append("descriptionOrExecutiveSummary", summary);
        formData.append("businessOrUseCase", problem);
        formData.append("desiredOutcome", desiredOutcome);
        formData.append("authorOrpresenterShortBio", authorOrpresenterShortBio || '');
        formData.append("authorOrpresenterSocialLinks", authorOrpresenterSocialLinks || '');
        formData.append("nameOfNonMicrosoftPresenter", nonMicrosoftPresenter);
        formData.append("segmentLength", segment);
        formData.append("metadataKeywords", metadata);
        formData.append("typeOfAudience", audience);
        formData.append("resourceLinks", resources);
        formData.append("miscNotes", extraInfo);
        formData.append("contentAssetDetails", contentAssetDetails);

        if (contentOwnerName && contentOwnerName[0]?.value) {
            formData.append("contentOwnerName", contentOwnerName[0].displayName || "");
            formData.append("contentOwnerId", contentOwnerName[0].localId || "");
            formData.append("contentOwnerEmail", contentOwnerName[0].mail || "");
        } else {
            formData.append("contentOwnerName", "");
            formData.append("contentOwnerId", "");
            formData.append("contentOwnerEmail", "");
        }

        if (assignedPMM && assignedPMM[0]?.value) {
            if (marketingCampaign === 'No') {
                formData.append("PPMAssignedName", '');
                formData.append("PPMAssignedId", '');
                formData.append("PPMAssignedEmail", '');
            }
            else {
                formData.append("PPMAssignedName", assignedPMM[0]?.displayName || "");
                formData.append("PPMAssignedId", assignedPMM[0]?.localId || "");
                formData.append("PPMAssignedEmail", assignedPMM[0]?.mail || "");
            }
        } else {
            formData.append("PPMAssignedName", '');
            formData.append("PPMAssignedId", '');
            formData.append("PPMAssignedEmail", '');
        }

        if (presenter && presenter[0]?.value) {
            formData.append("MicrosoftPresenterName", presenter[0]?.displayName || "");
            formData.append("MicrosoftPresenterID", presenter[0]?.localId || "");
            formData.append("MicrosoftPresenterEmail", presenter[0]?.mail || "");
        } else {
            formData.append("MicrosoftPresenterName", "");
            formData.append("MicrosoftPresenterID", "");
            formData.append("MicrosoftPresenterEmail", "");
        }

        formData.append("marketingCampaignOrUpcomingLaunch", marketingCampaign === 'Yes' ? true : false);
        const config = {
            headers: {
                'content-type': undefined,
            }
        };
        axios.put(BaseConfig.apiBaseUrl + 'triage/technicalRequestUpdate/' + sessionStorage.getItem('Emailid'), formData, config)
            .then(response => {
                setLoader(false)
                if (response.status === 200) {
                    setOpen200(true)
                    const delay = setTimeout(() => {
                        setOpen(false);
                        navigate('/technicalcontenthome')
                    }, 700);

                    return () => clearTimeout(delay);
                }
                else {
                    setOpenError(true)
                }
            })
            .catch(function (error) {
                console.error(error);
                setOpenError(true);
                setLoader(false)
            });
    }

    const handleSearchInputChange = async (userPropertyName, searchString) => {
        const responseData = await fetchData(searchString);
        if (userPropertyName === "contentOwnerNameOptions") {
            setContentOwnerNameOptions(responseData);
        } else if (userPropertyName === "assignedPMMOptions") {
            setAssignedPMMOptions(responseData)
        } else if (userPropertyName === "presenterOptions") {
            setPresenterOptions(responseData)
        }
    }

    const fetchData = async (searchString) => {
        var successfulSignIn = false;
        try {
            if (accounts.length > 0) {
                const accessTokenRequest = {
                    scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
                    account: accounts ? accounts[0] : null,
                };
                const response = await instance.acquireTokenSilent(accessTokenRequest);
                sessionStorage.setItem("userName", response.account.name);
                sessionStorage.setItem("Emailid", response.account.username);
                sessionStorage.setItem("accountId", response.account.localAccountId);
                sessionStorage.setItem("userToken", response.accessToken);
                setIsSignedIn(true)
                successfulSignIn = true
            } else {
                throw Error("not found any current active account")
            }
        } catch (error) {
            sessionStorage.clear();
            window.location.replace('/');
        }
        if (successfulSignIn) {
            const url =
                `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'@microsoft.com') and (startsWith(displayName,'${searchString}') or startsWith(mail,'${searchString}'))`;
            const headers = {
                ConsistencyLevel: "eventual",
                Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
                "Content-Type": "application/json",
            };
            try {
                const response = await axios.get(url, { headers });
                return response.data.value
            } catch (error) {
                console.error(error);
                return [];
            }
        }
    };

    const handleRequiredFields = () => {
        setButtonClicked(true)
        if (!assetType || assetType === '' || !title || title === '' || !focusedProduct || focusedProduct === '' || !summary || summary === '' || !problem || problem === '' || !desiredOutcome || desiredOutcome === '' || !location || location === '' || !timeZone || timeZone === '' || !fromDate || !toDate || !(contentOwnerName && contentOwnerName[0]?.value) || !(presenter && presenter[0]?.value)) {
            setOpenRequiredFields(true);
        }
        else if (!(!assetType || assetType === '' || !title || title === '' || !focusedProduct || focusedProduct === '' || !summary || summary === '' || !problem || problem === '' || !desiredOutcome || desiredOutcome === '' || !location || location === '' || !timeZone || timeZone === '' || !fromDate || !toDate)) {
            if (marketingCampaign === 'No') {
                update();
            }
            else {
                if (!assignedPMM[0]?.displayName) {
                    setOpenRequiredFields(true);
                }
                else {
                    update();
                }
            }
        }
        else {
            update();
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const loginProcess = async () => {
            var successfulSignIn = false;
            try {
                if (accounts.length > 0) {
                    const accessTokenRequest = {
                        scopes: ["https://graph.microsoft.com/.default"],
                        account: accounts ? accounts[0] : null,
                    };
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    sessionStorage.setItem("userName", response.account.name);
                    sessionStorage.setItem("Emailid", response.account.username);
                    sessionStorage.setItem("accountId", response.account.localAccountId);
                    sessionStorage.setItem("userToken", response.accessToken);
                    setIsSignedIn(true)
                    successfulSignIn = true
                } else {
                    throw Error("not found any current active account")
                }
            } catch (error) {
                console.log('Error acquiring token silently:', error);
                sessionStorage.clear();
                window.location.replace('/');
            }
            if (successfulSignIn) {
                getCardDetailsById();
                setOpen200(false);
                setOpenError(false);
            }
        }
        loginProcess();
        // eslint-disable-next-line
    }, []);

    try {
        return (
            <>
                {isSignedIn &&
                    <Box sx={{ display: 'flex' }}>
                        <Navbar portalName={"- Technical Content Requests"}></Navbar>
                        <MenubarForm handleRequiredFields={handleRequiredFields} OpenConfirmationPopup={OpenConfirmationPopup} ></MenubarForm>
                        <Main open={open}>
                            <DrawerHeader />
                            <div>
                                <Grid container>
                                    <Grid item xs={11} style={{ fontSize: '15px', marginBottom: '10px', marginTop: '40px' }}>
                                        <b> Comments </b>
                                    </Grid>
                                </Grid>

                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={comments}
                                        placeholder="Enter comments"
                                        onChange={(editorState) => { onEditorStateChange("comments", editorState) }}
                                    />
                                    <div
                                        style={{
                                            lineHeight: '1.2',
                                            fontSize: '12.5px',
                                            maxHeight: '90px', // Replace '300px' with your desired fixed height
                                            overflow: 'auto', // Use 'scroll' if you want to always show the scrollbar, or 'auto' for automatic scrollbar when needed
                                        }}
                                        dangerouslySetInnerHTML={{ __html: previousComments }}
                                    />
                                </div>

                                <Grid container paddingTop={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Request Id </b></div>
                                        <div style={{ paddingTop: '7px' }}>
                                            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> {cardInfo ? cardInfo[0]?.fields['Custom.RequestID'] : ''} </b></div>
                                        </div>
                                    </Grid>

                                    <Grid xs={4}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Request Style </b></div>
                                        <div style={{ paddingTop: '7px' }}>
                                            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> {cardInfo ? cardInfo[0]?.fields['Custom.RequestStyle'] : ''} </b></div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingTop={3} paddingBottom={3} >
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Request Created by </b></div>
                                        <div style={{ paddingTop: '7px' }}>
                                            <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> {cardInfo ? cardInfo[0]?.fields['Custom.RecordCreatedBy'] : ''} </b></div>
                                        </div>
                                    </Grid>

                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Status </b></div>
                                        <div >
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={statusSelected}
                                                    onChange={(event) => { setStatusSelected(event.target.value) }}
                                                >
                                                    {statuslist.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!assetType || assetType === '') ? 'red' : '' }}><b> Content Asset Type* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <FormControl sx={{ width: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={assetType}
                                                    onChange={(event) => { setAssetType(event.target.value) }}
                                                >
                                                    {contentAssetList.map((item) => (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>

                                    <Grid xs={6}>

                                    </Grid>

                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!title || title === '') ? 'red' : '' }}><b> Title* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter title"
                                                style={{ width: '100%' }}
                                                value={title}
                                                onChange={(event) => { setTitle(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Business Area </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                placeholder="Enter Business Area"
                                                size="small"
                                                style={{ width: '100%' }}
                                                value={businessArea}
                                                onChange={(event) => { setBusinessArea(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!focusedProduct || focusedProduct === '') ? 'red' : '' }}><b> Product, service or feature name* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter Focused product, service or feature name"
                                                style={{ width: '100%' }}
                                                value={focusedProduct}
                                                onChange={(event) => { setFocusedProduct(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12} sm={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && contentOwnerName.length === 0 ? 'red' : '' }}><b> Content Owner Name* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <Reactselect
                                                value={contentOwnerName}
                                                onChange={(option) => {
                                                    option ? setContentOwnerName([{ ...option, label: option.displayName }]) : setContentOwnerName([])
                                                }}
                                                onInputChange={(searchString) => {
                                                    handleSearchInputChange("contentOwnerNameOptions", searchString)
                                                }}
                                                // styles={customStyles}
                                                isClearable
                                                options={contentOwnerNameOptions.map((option) => ({
                                                    value: option.displayName + " | " + option.mail,
                                                    displayName: option.displayName || "",
                                                    mail: option.mail || "",
                                                    localId: option.localId || "",
                                                    label: (
                                                        <>
                                                            <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                            <div style={{ marginTop: '0.2rem' }}>
                                                                <div>
                                                                    <b>
                                                                        <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                    </b>
                                                                    <br />
                                                                    <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }))}
                                                placeholder="Search Content Owner Name"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!summary || summary === '') ? 'red' : '' }}><b> Description, abstract or executive summary* </b></div>
                                <div >
                                    <ReactQuill
                                        theme="snow"
                                        value={summary}
                                        placeholder="Enter Description, abstract or executive summary"
                                        onChange={(editorState) => { onEditorStateChange("summary", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '60px', color: buttonClicked && (!problem || problem === '') ? 'red' : '' }}><b> What is the problem you are trying to solve? What is your business or use case?* </b></div>
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={problem}
                                        placeholder="Enter business or use case"
                                        onChange={(editorState) => { onEditorStateChange("problem", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '60px', color: buttonClicked && (!desiredOutcome || desiredOutcome === '') ? 'red' : '' }}><b> What is you desired outcome? How did you solve the problem using AI technology?* </b></div>
                                <div >
                                    <ReactQuill
                                        theme="snow"
                                        value={desiredOutcome}
                                        placeholder="Enter desired outcome"
                                        onChange={(editorState) => { onEditorStateChange("desiredOutcome", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <Grid container paddingTop={7.5} paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Is this request part of a marketing campaign or upcoming product launch?* </b></div>
                                        <div >
                                            <RadioGroup
                                                id="marketingCampaign"
                                                name="marketingCampaign"
                                                value={marketingCampaign}
                                                onChange={(event) => { setMarketingCampaign(event.target.value); setAssignedPMM([]) }}
                                            >
                                                <div>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label='Yes'
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label='No'
                                                    />
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (marketingCampaign === 'Yes') && (!cardInfo[0]?.fields['Custom.PPMAssignedToName'] || cardInfo[0]?.fields['Custom.PPMAssignedToName'] === '') ? 'red' : '' }}><b> Name of PMM assigned to this product or service{(marketingCampaign === 'Yes') ? '*' : ''} </b></div>
                                        <div>
                                            <Reactselect
                                                value={assignedPMM}
                                                isDisabled={(marketingCampaign === "No") ? true : false}
                                                onChange={(option) => {
                                                    option ? setAssignedPMM([{ ...option, label: option.displayName }]) : setAssignedPMM([])
                                                }}
                                                onInputChange={(searchString) => {
                                                    handleSearchInputChange("assignedPMMOptions", searchString)
                                                }}
                                                // styles={customStyles}
                                                isClearable
                                                options={assignedPMMOptions.map((option) => ({
                                                    value: option.displayName + " | " + option.mail,
                                                    displayName: option.displayName || "",
                                                    mail: option.mail || "",
                                                    localId: option.localId || "",
                                                    label: (
                                                        <>
                                                            <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                            <div style={{ marginTop: '0.2rem' }}>
                                                                <div>
                                                                    <b>
                                                                        <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                    </b>
                                                                    <br />
                                                                    <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }))}
                                                placeholder="Search assigned PMM"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && presenter.length === 0 ? 'red' : '' }}><b> Name of Microsoft Author/presenter(s)*</b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <Reactselect
                                                value={presenter}
                                                onChange={(option) => {
                                                    option ? setPresenter([{ ...option, label: option.displayName }]) : setPresenter([])
                                                }}
                                                onInputChange={(searchString) => {
                                                    handleSearchInputChange("presenterOptions", searchString)
                                                }}
                                                // styles={customStyles}
                                                isClearable
                                                options={presenterOptions.map((option) => ({
                                                    value: option.displayName + " | " + option.mail,
                                                    displayName: option.displayName || "",
                                                    mail: option.mail || "",
                                                    localId: option.localId || "",
                                                    label: (
                                                        <>
                                                            <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                                                            <div style={{ marginTop: '0.2rem' }}>
                                                                <div>
                                                                    <b>
                                                                        <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                    </b>
                                                                    <br />
                                                                    <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }))}
                                                placeholder="Search Content Owner Name"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Name of non-Microsoft Author/presenter(s) </b></div>
                                        <div style={{}}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter non-Microsoft presenter(s) name"
                                                style={{ width: '100%' }}
                                                value={nonMicrosoftPresenter}
                                                onChange={(event) => { setNonMicrosoftPresenter(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container paddingBottom={8}>
                                    <Grid xs={6} sm={6} paddingRight={3}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Author/presenter(s) short bio </b></div>
                                        <div >
                                            <ReactQuill
                                                theme="snow"
                                                value={authorOrpresenterShortBio}
                                                placeholder="Enter Author/presenter(s) short bio"
                                                onChange={(editorState) => { onEditorStateChange("authorOrpresenterShortBio", editorState) }}
                                                style={{ maxWidth: '800px', height: '170px' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid xs={6} sm={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Author/presenter(s) social links </b></div>
                                        <div >
                                            <ReactQuill
                                                theme="snow"
                                                value={authorOrpresenterSocialLinks}
                                                placeholder="Enter Author/presenter(s) social links"
                                                onChange={(editorState) => { onEditorStateChange("authorOrpresenterSocialLinks", editorState) }}
                                                style={{ maxWidth: '800px', height: '170px' }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container paddingBottom={3}>
                                    <Grid xs={6} style={{ paddingRight: '20px' }}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Author/presenter(s) headshot </b></div>
                                        <Grid container>
                                            <Grid xs={10}>
                                                <FileDropzone onDrop={(event) => { handleDrop("authorOrPresenterHeadshot", event) }}>
                                                    <input
                                                        type="text"
                                                        placeholder="Drag and Drop Attachment"
                                                        readOnly
                                                        className="file-name"
                                                        style={{ width: '95%', height: '40px' }}
                                                    />
                                                </FileDropzone>
                                                {updatedAuthorOrPresenterHeadshotAttachment ?
                                                    <span style={{ fontSize: '13px' }}>{authorOrPresenterHeadshotAttachmentName}  <i style={{ color: 'red' }}>(unsaved)</i></span>
                                                    :
                                                    <MUILink href={cardInfo ? cardInfo[0]?.fields['Custom.AuthorOrPresenterHeadshotAttachmentURL'] + `?fileName=${cardInfo[0]?.fields['Custom.AuthorOrPresenterHeadshotAttachmentName']}&download={download}&api-version=7.0` : ''}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'inherit' }}
                                                    >
                                                        <span style={{ fontSize: '13px' }}>{authorOrPresenterHeadshotAttachmentName}</span>
                                                    </MUILink>
                                                }
                                            </Grid>
                                            <Grid xs={2}>
                                                <label className="upload-btn">
                                                    Upload
                                                    <input
                                                        type="file"
                                                        onChange={(event) => { handleFileChange("authorOrPresenterHeadshot", event) }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!location || location === '') ? 'red' : '' }}><b> Where is the presenter(s) located?* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter presenter(s) location"
                                                style={{ width: '100%' }}
                                                value={location}
                                                onChange={(event) => { setLocation(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!timeZone || timeZone === '') ? 'red' : '' }}><b> What is presenter(s) TimeZone?* </b></div>
                                        <div style={{}}>
                                            <TimezoneSelect
                                                id="timeZone"
                                                name="timeZone"
                                                placeholder="Select timezone"
                                                styles={customStyles}
                                                value={timeZone}
                                                onChange={(timezone) => { setTimeZone(timezone.value) }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!fromDate || fromDate === '') ? 'red' : '' }}><b> What is your desired publication date?* </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="fromDate"
                                                name="fromDate"
                                                type="date"
                                                size='small'
                                                style={{ width: '100%' }}
                                                value={fromDate}
                                                onChange={(event) => { setFromDate(event.target.value) }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px', color: buttonClicked && (!toDate || toDate === '') ? 'red' : '' }}><b> Expected expiration date? (cannot exceed 1 year)* </b></div>
                                        <div >
                                            <TextField
                                                id="toDate"
                                                name="toDate"
                                                type="date"
                                                size='small'
                                                style={{ width: '100%' }}
                                                value={toDate}
                                                onChange={(event) => { setToDate(event.target.value) }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Length of segment/session (in minutes) </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                placeholder="Enter session length (in mins)"
                                                style={{ width: '100%' }}
                                                onBlur={() => {
                                                    if (segment && segment < 0) {
                                                        setSegment(0);
                                                    } else {
                                                        const enteredValue = parseFloat(segment);
                                                        if (segment && !Number.isInteger(segment)) {
                                                            setSegment(Math.floor(enteredValue))
                                                        }
                                                    }
                                                }}
                                                value={segment}
                                                onChange={(event) => { setSegment(event.target.value) }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid xs={6}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Provide metadata: 5-6 terms, phrases or keywords relevant to your content </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter metadata keywords"
                                                style={{ width: '100%' }}
                                                value={metadata}
                                                onChange={(event) => { setMetaData(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container paddingBottom={3}>
                                    <Grid xs={12}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Who is the target audience(s)? </b></div>
                                        <div style={{ paddingRight: '20px' }}>
                                            <TextField
                                                id="outlined-size-small"
                                                size="small"
                                                placeholder="Enter type of audience are you anticipating to reach"
                                                style={{ width: '100%' }}
                                                value={audience}
                                                onChange={(event) => { setAudience(event.target.value) }}
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ fontSize: '14px', marginBottom: '10px' }}><b>Provide 2-3 resource links - Where should viewers go to learn more? Include links to Docs pages / Learn modules / code samples / etc. </b></div>
                                <div >
                                    <ReactQuill
                                        theme="snow"
                                        value={resources}
                                        placeholder="Enter resource links"
                                        onChange={(editorState) => { onEditorStateChange("resources", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '60px' }}><b> Is there anything else you would like us to know about this request? </b></div>
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        placeholder="Enter additional inforation you would like us to know about this request"
                                        value={extraInfo}
                                        onChange={(editorState) => { onEditorStateChange("extraInfo", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>
                                <Grid container paddingTop={8}>
                                    <Grid xs={6} style={{ paddingRight: '20px' }}>
                                        <div style={{ fontSize: '14px', marginBottom: '10px' }}><b> Upload additional documentation/graphics </b></div>
                                        <Grid container>
                                            <Grid xs={10}>
                                                <FileDropzone onDrop={(event) => { handleDrop("additionalDocsOrGraphics", event) }}>
                                                    <input
                                                        type="text"
                                                        placeholder="Drag and Drop Attachment"
                                                        readOnly
                                                        className="file-name"
                                                        style={{ width: '95%', height: '40px' }}
                                                    />
                                                </FileDropzone>
                                                {updatedAdditionalDocsOrGraphicsAttachment ?
                                                    <span style={{ fontSize: '13px' }}>{additionalDocsOrGraphicsAttachmentName}  <i style={{ color: 'red' }}>(unsaved)</i></span>
                                                    :
                                                    <MUILink href={cardInfo ? cardInfo[0]?.fields['Custom.AdditionalDocsOrGraphicsAttachmentURL'] + `?fileName=${cardInfo[0]?.fields['Custom.AdditionalDocsOrGraphicsAttachmentName']}&download={download}&api-version=7.0` : ''}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'inherit' }}
                                                    >
                                                        <span style={{ fontSize: '13px' }}>{additionalDocsOrGraphicsAttachmentName}</span>
                                                    </MUILink>
                                                }
                                            </Grid>
                                            <Grid xs={2}>
                                                <label className="upload-btn">
                                                    Upload
                                                    <input
                                                        type="file"
                                                        onChange={(event) => { handleFileChange("additionalDocsOrGraphics", event) }}
                                                        style={{ display: 'none' }}
                                                    />
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div style={{ fontSize: '14px', marginBottom: '10px', paddingTop: '20px' }}><b> Content Asset Details </b></div>
                                <div >
                                    <ReactQuill
                                        theme="snow"
                                        value={contentAssetDetails}
                                        placeholder="Enter content asset details"
                                        onChange={(editorState) => { onEditorStateChange("contentAssetDetails", editorState) }}
                                        style={{ maxWidth: '800px', height: '200px' }}
                                    />
                                </div>

                                <Grid container paddingTop={1} paddingBottom={3}>
                                    <Grid xs={8}>

                                    </Grid>

                                    <Grid xs={3} />

                                    <Grid xs={1}>
                                    </Grid>
                                </Grid>
                            </div>
                        </Main>

                        <Snackbar open={open200} autoHideDuration={4000} onClose={() => { setOpen200(false) }}>
                            <Alert onClose={() => { setOpen200(false) }} severity="success" sx={{ width: '100%' }}>
                                Request Updated Sucessfully
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openRequiredfields} autoHideDuration={4000} onClose={() => { setOpenRequiredFields(false) }}>
                            <Alert onClose={() => { setOpenRequiredFields(false) }} severity="error" sx={{ width: '100%' }}>
                                Fill Required Fields
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={4000} onClose={() => { setOpenError(false) }}>
                            <Alert onClose={() => { setOpenError(false) }} severity="error" sx={{ width: '100%' }}>
                                Failed to Update Request. Try Again.
                            </Alert>
                        </Snackbar>

                        <Dialog open={confirmationDelete} onClose={() => { setConfirmationDelete(false) }}>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are u sure you want to delete this request?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={() => { setConfirmationDelete(false) }} >
                                    Cancel
                                </Button>
                                <Button onClick={handleRequestDelete} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box >
                }
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}