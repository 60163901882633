import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const ExceptionHandled = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="body1" align="center">
        Something went wrong! Please reach out to engageai@microsoft.com.
        </Typography>
        {/* Add your contact information or link to contact here */}
      </Box>
    </Container>
  );
};

export default ExceptionHandled;