import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
// import logo from './../../assets/Images/Microsoft Icon -Flow 2.avif'
// import logoutImg from './../../assets/Images/logout (2).png';
// import { Avatar, Divider } from '@mui/material';
// import { ArrowBack } from '@mui/icons-material';
import mailSendInmg from './../../assets/Images/Heder/Mail-send.png';
import HomeImg from './../../assets/Images/Heder/Home.png'
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

const drawerWidth = 337;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    height: '50px',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function Navbar(props) {
    // eslint-disable-next-line no-unused-vars
    const [triageEdit, setTriageEdit] = React.useState(sessionStorage.getItem("triageEdit") === "yes" ? true : false);

    try {
        return (
            <>
                <CssBaseline />
                <AppBar position="fixed" style={{ backgroundColor: '', marginTop: '53px' }}>
                    <Toolbar>

                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="Settings"
                            aria-haspopup="false"
                            onClick={() => { window.history.back() }}
                        >
                            <ArrowBack style={{ color: 'white', fontSize: '30px' }} ></ArrowBack>
                        </IconButton>


                        <Link to={'/home'}>
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="Settings"
                                aria-haspopup="false"
                            >
                                <img
                                    src={HomeImg}
                                    alt='logout'
                                    style={{
                                        width: '28px',
                                        height: '28px',
                                    }}
                                />
                            </IconButton>
                        </Link>

                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <IconButton
                                size="large"
                                edge="start"
                                aria-label="chatbot"
                                aria-haspopup="false"
                            >
                                <img
                                    src={ListImg}
                                    alt='logout'
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            </IconButton> */}
                            </div>


                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="Settings"
                                aria-haspopup="false"
                                onClick={triageEdit ? props.sendMail : null}
                            >
                                <img
                                    src={mailSendInmg}
                                    alt='logout'
                                    style={{
                                        width: '28px',
                                        height: '28px',
                                        opacity: triageEdit ? '1' : '0.5', // Adjust opacity for disabled effect
                                        pointerEvents: triageEdit ? 'auto' : 'none', // Disable pointer events
                                    }}
                                />
                            </IconButton>
                        </Box>

                    </Toolbar>
                </AppBar>
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}