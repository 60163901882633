import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Checkbox, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, List, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Reactselect from 'react-select';
import { Link } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { Menu } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from './../../assets/Images/Logo.png'
import logoutImg from './../../assets/Images/logout (2).png';
import sharepointImg from './../../assets/Images/SharePoint_(2019present).png';
import powerbiImg from './../../assets/Images/powerbi.png';
import EyeOpenImg from './../../assets/Images/view.png';
import EyeCloseImg from './../../assets/Images/eye.png'
import CopyImg from './../../assets/Images/copy.png'
import mailImg from './../../assets/Images/mail.png'
import editImg from './../../assets/Images/edit.png';
import filterImg from './../../assets/Images/filter.png';
import technicalContentImg from './../../assets/Images/technicalApp.png';
import drawerMailImg from './../../assets/Images/Mail_(iOS).png'
import findSpeakerImg from './../../assets/Images/search.png'
import { orderBy } from 'lodash';
import { useMsal } from '@azure/msal-react';

import axios from 'axios';
import Navbar from '../components/Navbar';
import BaseConfig from '../../config/config';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "....";
  }
  return text;
}

const statuslist = [
  { value: 'Speaker Requested', label: 'Speaker Requested' },
  { value: 'In Triage', label: 'In Triage' },
  { value: 'Speaker Accepted', label: 'Speaker Accepted' },
  { value: 'Speaker Assigned', label: 'Speaker Assigned' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Action Required', label: 'Action Required' },
  { value: 'Request Cancelled', label: 'Request Cancelled' },
  { value: 'Request Unfulfilled', label: 'Request Unfulfilled' },
  { value: 'Meeting Cancelled', label: 'Meeting Cancelled' },
  { value: 'Meeting Rescheduled', label: 'Meeting Rescheduled' },
  { value: 'Speaker Cancelled', label: 'Speaker Cancelled' },
];

const engagementTypeOptions = [
  {
    label: "Customer Briefing (non-EBC)",
    value: "Customer Briefing (non-EBC)",
  },
  {
    label: "RoadMap",
    value: "Roadmap",
  },
  {
    label: "Rapid Protoype (POC)",
    value: "Rapid Prototype (POC)",
  },
  {
    label: "Technical Workshop",
    value: "Technical Workshop",
  },
  {
    label: "Architecture design",
    value: "Architecture design",
  },
  {
    label: "Internal Microsoft Event/Conference",
    value: "Internal Microsoft Event/Conference",
  },
  {
    label: "External Event/Conference",
    value: "External Event/Conference",
  }
]

const reactSelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '40px',
    height: '40px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),
};

const topiclist = [
  { label: 'Azure Machine Learning Platform', serviceOptions: ['Azure Machine Learning', 'Responsible AI with Azure'] },
  { label: 'Azure AI Services', serviceOptions: ['Azure AI Services', 'Azure AI Bot Service', 'Azure AI Decision', 'Azure AI Document Intelligence', 'Azure AI Immersive Reader', 'Azure AI Language', 'Azure AI Metrics Advisor', 'Azure AI Speech', 'Azure AI Video Indexer', 'Azure AI Vision', 'Azure Cognitive Search', 'Azure OpenAI Service'] },
  { label: 'Custom AI/ML topic', serviceOptions: [] },
];

const sortList = [
  { label: 'Created On - Newest to Oldest', value: 'Created On - Newest to Oldest' },
  { label: 'Created On - Oldest to Newest', value: 'Created On - Oldest to Newest' },
  { label: 'From - Newest to Oldest', value: 'From - Newest to Oldest' },
  { label: 'From - Oldest to Newest', value: 'From - Oldest to Newest' },
];


const itemsBelow = [
  {
    index: '1', text: 'E-Mail', img: <img
      src={drawerMailImg}
      alt=''
      style={{
        width: '21px',
        height: '21px',
      }}
    />,
    url: '/defaultmail'
  },
  {
    index: '1', text: 'AI Insights', img: <img
      src={powerbiImg}
      alt=''
      style={{
        width: '21px',
        height: '21px',
      }}
    />,
    url: 'http://aka.ms/aiinsights'
  },
  {
    index: '2', text: 'AI Requests', img: <img
      src={sharepointImg}
      alt=''
      style={{
        width: '21px',
        height: '21px',
      }}
    />,
    url: 'https://microsoft.sharepoint.com/teams/WWAISpeakerEngagements/Lists/EngagementRequests/'
  },
  {
    index: '3', text: 'myCE', img: <img
      src={logo}
      alt=''
      style={{
        width: '21px',
        height: '21px',
      }}
    />,
    url: 'https://myce.azureedge.net/'
  },
  {
    index: '3', text: 'Speaker Escalation', img: <img
      src={powerbiImg}
      alt=''
      style={{
        width: '21px',
        height: '21px',
      }}
    />,
    url: 'https://msit.powerbi.com/groups/me/apps/df8b8a78-52f4-4df8-b379-c58615fba4f2/reports/7af65c81-067d-4ecc-a881-d3729a970664'
  },
  {
    index: '4', text: 'Triage (Technical Content Request)',
    img: <img
      src={technicalContentImg}
      alt=''
      style={{
        width: '30px',
        height: '30px',
      }}
    />,
    url: '/technicalcontenthome'
  },
  {
    index: '5', text: 'Find Speaker',
    img: <img
      src={findSpeakerImg}
      alt=''
      style={{
        width: '30px',
        height: '30px',
      }}
    />,
    url: '/findSpeaker'
  },
];

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: open ? 'wrap' : 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E9EFF2',
    padding: theme.spacing(1.1),
    textDecoration: 'none',
    borderLeft: 'grey'
  },
  image: {
    width: '100%',
    height: '140',
    backgroundColor: '#ffffff',
    marginBottom: '13',
  },
  description: {
    marginTop: theme.spacing(2.5),
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    fontFamily: 'Segoe UI Regular'
  },
}));

export default function DashboardContent() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { instance, inProgress, accounts } = useMsal();
  const [cards, setCards] = React.useState([]);
  const [status, setStatus] = React.useState('');
  const [session, setSession] = React.useState('');
  const [engagementType, setEngagementType] = React.useState([]);
  const [menuopen, setMenuopen] = React.useState(false);
  const [menuItemChanged, setMenuItemChanged] = React.useState(false);
  const [searchProgress, SetSearchProgress] = React.useState(false)
  const [topic, setTopic] = React.useState('');
  const [searchedProperty, setSearchedProperty] = React.useState('');
  const [serviceInterestOptions, setServiceInterestOptions] = React.useState([]);
  const [sortby, setSortBy] = React.useState('Created On - Newest to Oldest');
  const [showProgress, setShowProgress] = React.useState(true);
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [show200Records, setShow200Records] = React.useState(true);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);


  const handleSortSelected = (value) => {
    setSortBy(value);
    if (value === 'From - Oldest to Newest') {
      const sortOrder = 'asc';
      const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.FromDate'])], [sortOrder]);
      setCards(sortedData);
    }
    else if (value === 'From - Newest to Oldest') {
      const sortOrder = 'desc';
      const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.FromDate'])], [sortOrder]);
      setCards(sortedData);
    }
    else if (value === 'Created On - Newest to Oldest') {
      const sortOrder = 'desc';
      const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.CreatedOn'])], [sortOrder]);
      setCards(sortedData);
    }
    else if (value === 'Created On - Oldest to Newest') {
      const sortOrder = 'asc';
      const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.CreatedOn'])], [sortOrder]);
      setCards(sortedData);
    }
  }

  const handleStatusSelected = (value) => {
    setStatus(value);
    getCardsByProperty('status', value);
    sessionStorage.setItem('status', value)
  }

  const handleTopicSelected = (value) => {
    setTopic(value);
    getCardsByProperty('topic', value);
    sessionStorage.setItem('topic', value);
    setSession('');
  }

  const handleSessionSelected = (value) => {
    setSession(value);
    getCardsByProperty('session', value);
    sessionStorage.setItem('session', value);
  }

  const handleAttendiesChange = (name, options) => {
    const selectedValues = options.map((option) => option.value)
    this.setState({ [name]: options })
    this.props.setParameters([name], selectedValues)
  };

  const clearAll = () => {
    setSearchedProperty('');
    setSession('');
    setTopic('');
    setStatus('');
    setEngagementType([]);
    getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
    sessionStorage.removeItem('status');
    sessionStorage.removeItem('topic');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('engagementType');
    sessionStorage.removeItem('property');
  }

  const handleShowToggle = (event) => {
    sessionStorage.setItem('show200Records', event.target.checked)
    getAllCards(event.target.checked)
  }

  const getAllCards = (value) => {
    setShowProgress(true);
    setShow200Records(value);
    const formData = new FormData();
    formData.append("extractLessData", value);
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.post(BaseConfig.apiBaseUrl + 'triage/' + sessionStorage.getItem('Emailid'), formData, config)
      .then(response => {
        if (response.data.status) {
          setCards(value ? response.data.data.value : response.data.data)
          setShowProgress(false);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const search = (event) => {
    sessionStorage.setItem('property', event.target.value)
    setSearchedProperty(event.target.value);
    getCardsByProperty('property', event.target.value);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const loginProcess = async () => {
      var successfulSignIn = false;
      try {
        if (accounts.length > 0) {
          const accessTokenRequest = {
            scopes: ["https://graph.microsoft.com/.default"],
            account: accounts ? accounts[0] : null,
          };
          const response = await instance.acquireTokenSilent(accessTokenRequest);
          sessionStorage.setItem("userName", response.account.name);
          sessionStorage.setItem("Emailid", response.account.username);
          sessionStorage.setItem("accountId", response.account.localAccountId);
          sessionStorage.setItem("userToken", response.accessToken);
          setIsSignedIn(true)
          successfulSignIn = true
        } else {
          throw Error("not found any current active account")
        }
      } catch (error) {
        sessionStorage.clear();
        window.location.replace('/');
      }
      if (successfulSignIn) {
        if (sessionStorage.getItem('status') || sessionStorage.getItem('topic') || sessionStorage.getItem('session') || sessionStorage.getItem('property') || sessionStorage.getItem('engagementType')) {
          getCardsBySessionProperty();
        }
        else {
          getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
        }
      }
    }
    loginProcess();
    // eslint-disable-next-line
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getCardsByProperty = (property, value) => {
    const formData = new FormData();
  let propertySerached = false;
  let propertyValue ;

    if ((property === 'property' || searchedProperty !== '')) {
      formData.append("property", property === 'property' ? value : searchedProperty);
      propertySerached = true;
      propertyValue = property === 'property' ? value : searchedProperty; 
      SetSearchProgress(true);
    }
    if ((property === 'status' || status !== '')) {
      formData.append("status", property === 'status' ? value : status);
    }
    if ((property === 'engagementType' || (engagementType !== '' && engagementType.length > 0))) {
      const formattedEngagementType = property === 'engagementType' ? value.map((option) => option.value).join(',') : engagementType.map((option) => option.value).join(',');
      formData.append("engagementType", formattedEngagementType);
    }
    if ((property === 'topic')) {
      formData.append("topic", property === 'topic' ? value : topic);
      formData.append("session", '')
    } else if (topic !== '') {
      formData.append("topic", topic);
    }
    if ((property === 'session') || (property !== 'topic')) {
      formData.append("session", property === 'session' ? value : session);
    }
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.post(BaseConfig.apiBaseUrl + 'triage/getRequestByProperty', formData, config)
      .then(response => {
        if (response.data.status) {
          if(propertySerached){
          if(propertyValue===sessionStorage.getItem('property')){
            SetSearchProgress(false)
            setCards(response.data.data.value)
          }
          }else{
            setCards(response.data.data.value)
          }
        }
      })
      .catch(function (error) {
        console.error(error);
        setCards([]);
      });
  }

  const getCardsBySessionProperty = () => {
    const formData = new FormData();
    if (sessionStorage.getItem('property')) {
      setSearchedProperty(sessionStorage.getItem('property'))
      formData.append("property", sessionStorage.getItem('property'));
    }
    if (sessionStorage.getItem('engagementType')) {
      const sessionEngagementTypeData = JSON.parse(sessionStorage.getItem('engagementType'))?.map((option) => option.value).join(',');
      setEngagementType(JSON.parse(sessionStorage.getItem('engagementType')))
      formData.append("engagementType", sessionEngagementTypeData);
    }
    if (sessionStorage.getItem('status')) {
      setStatus(sessionStorage.getItem('status'))
      formData.append("status", sessionStorage.getItem('status'));
    }
    if (sessionStorage.getItem('topic')) {
      setTopic(sessionStorage.getItem('topic'))
      let topicServices = topiclist.find(topic => topic.label === sessionStorage.getItem('topic'))
      setServiceInterestOptions(topicServices?.serviceOptions);
      formData.append("topic", sessionStorage.getItem('topic'));
    }
    if (sessionStorage.getItem('session')) {
      setSession(sessionStorage.getItem('session'))
      formData.append("session", sessionStorage.getItem('session'));
    }
    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.post(BaseConfig.apiBaseUrl + 'triage/getRequestByProperty', formData, config)
      .then(response => {
        if (response.data.status) {
          setCards(response.data.data.value)
          setShowProgress(false)
        }
      })
      .catch(function (error) {
        console.error(error);
        setCards([]);
        setShowProgress(false);
      });
  }

  let initials = null;
  try {
    const userName = sessionStorage.getItem('userName');
    if (userName !== null) {
      // eslint-disable-next-line
      initials = userName?.split(' ')
        .map(word => word.charAt(0))
        .join('');
    }
  } catch (error) {
    // Handle the error, if needed
  }

  try {
    return (
      <>
        {isSignedIn &&
          <Box sx={{ display: 'flex' }}>
            <Navbar portalName={"- Engagement Requests"}></Navbar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                  <List>
                    <ListItem disablePadding sx={{ display: 'block' }} >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <Menu style={{ color: 'black' }} />
                        </ListItemIcon>

                        <ListItemText
                          primary="EngageAI Triage"
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        > </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <Divider />

                  <List >
                    {itemsBelow.map(({ text, img, url }) => (
                      <ListItem key={text} disablePadding sx={{ display: 'block' }} >
                        <MUILink href={url}
                          target={(url === '/technicalcontenthome' || url === '/findSpeaker' || url === '/defaultmail') ? '_self' : '_blank'}
                          rel="noopener noreferrer"
                          style={{ color: 'inherit', textDecoration: 'inherit' }}>
                          <Tooltip
                            title={text}
                            disableHoverListener={open}
                            placement='right'
                            arrow
                          >
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'center',
                                }}
                              >
                                {img}


                              </ListItemIcon>

                              <ListItemText
                                primary={text}
                                sx={{
                                  opacity: open ? 1 : 0,
                                }}
                              ></ListItemText>
                              {text === 'Usage & Subscription' && open && (
                                <img
                                  src={logoutImg}
                                  alt={text}
                                  style={{
                                    width: '21px',
                                    height: '21px',
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                  }}
                                />
                              )}
                            </ListItemButton>
                          </Tooltip>
                        </MUILink>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              <div className="new-application-note" style={{marginBottom : '30px'}}>
                <span>
                  Welcome to the new Engage AI Triage application! This is a Beta release. If you have any questions please reach out to{" "}
                  <a
                    href="mailto:engageai@microsoft.com"
                    title="mailto:engageai@microsoft.com"
                  >
                    engageai@microsoft.com
                  </a>
                </span>
              </div>
              {/* <Grid item xs={12} style={{ fontSize: '18px', marginBottom: '20px', fontFamily: 'Segoe UI Regular' }}><b> Welcome Back, {sessionStorage.getItem('userName')?.split(' ')[0]} </b></Grid> */}

              <Grid container style={{border: '1.5px solid #ccc',borderRadius:'10px'}} >
                  <div style={{ display: 'flex', alignItems: 'center', height: '100%',backgroundColor:'white',marginTop : '-12px',marginLeft : '20px',paddingLeft : '5px',paddingRight:'5px' }}>
                    <img
                      src={filterImg}
                      alt=''
                      style={{
                        width: '21px',
                        height: '21px',
                        marginRight: '7px'
                      }}
                    />
                    Filters
                  </div>
                <Grid xs={12}>
                  <Grid container paddingBottom={1} paddingTop={1}>
                    <Grid xs={4}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px',paddingLeft: '20px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            isClearable
                            id="demo-select-small"
                            name="Filter by Status"
                            placeholder="Filter by Status"
                            styles={reactSelectCustomStyles}
                            options={statuslist.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            value={status ? {
                              value: status,
                              label: status,
                            } : ''}
                            onChange={(selectedOptions) => {
                              if (selectedOptions) {
                                handleStatusSelected(selectedOptions.value)
                              } else {
                                setStatus('')
                                getCardsByProperty('status', '')
                                sessionStorage.removeItem('status')
                              }
                            }
                            }
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid xs={4}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            isClearable
                            id="demo-select-small"
                            name="Filter by Topic"
                            placeholder="Filter by Topic"
                            styles={reactSelectCustomStyles}
                            options={topiclist.map((item) => ({
                              value: item.label,
                              label: item.label,
                            }))}
                            value={topic ? {
                              value: topic,
                              label: topic,
                            } : ''}
                            onChange={(selectedOptions) => {
                              if (selectedOptions) {
                                handleTopicSelected(selectedOptions.value);
                                const selectedItem = topiclist.find(item => item.label === selectedOptions.value);
                                setServiceInterestOptions(selectedItem.serviceOptions);
                              } else {
                                setTopic('')
                                getCardsByProperty('topic', '')
                                sessionStorage.removeItem('topic');
                                sessionStorage.removeItem('session');
                                setServiceInterestOptions([])
                              }
                            }
                            }
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid xs={4}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            isClearable
                            id="demo-select-small"
                            name="Filter by Service"
                            placeholder="Filter by Service"
                            styles={reactSelectCustomStyles}
                            options={serviceInterestOptions.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            value={session ? {
                              value: session,
                              label: session,
                            } : ''}
                            // styles={customStyles}
                            onChange={(selectedOptions) => {
                              if (selectedOptions) {
                                handleSessionSelected(selectedOptions.value)
                              } else {
                                setSession('')
                                getCardsByProperty('session', '');
                                sessionStorage.removeItem('session');
                              }
                            }
                            }
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                        {/* <Button variant="contained" style={{ width: '20px', height: '40px', backgroundColor: 'white', border: '1px solid #2088ff', borderRadius: '0px' }} onClick={handleClearServiceClick} >
                    <Close style={{ color: '#2088ff' }} onClick={handleClearServiceClick} />
                  </Button> */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container paddingBottom={2} >
                    <Grid xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px'}}>
                        <FormControl sx={{ flexGrow: 1 }} size="small">
                          <Reactselect
                            id="engagementType"
                            name="engagementType"
                            placeholder="Filter by Engagement Type"
                            options={engagementTypeOptions.map((option) => ({
                              value: option.value,
                              label: option.label,
                            }))}
                            closeMenuOnSelect={false}
                            onMenuClose={() => {
                              if (engagementType.length > 0 && menuItemChanged === true) {
                                setMenuItemChanged(false);
                                getCardsByProperty('engagementType', engagementType);
                              }
                              setMenuopen(false);
                            }}
                            onMenuOpen={() => {
                              setMenuopen(true)
                            }}
                            isMulti
                            value={engagementType}
                            styles={reactSelectCustomStyles}
                            onChange={(selectedOptions) => {
                              setEngagementType(selectedOptions);
                              setMenuItemChanged(true);
                              if (menuopen === false) {
                                getCardsByProperty('engagementType', selectedOptions);
                              }
                              if (selectedOptions && selectedOptions.length > 0) {
                                sessionStorage.setItem('engagementType', JSON.stringify(selectedOptions));
                              } else {
                                sessionStorage.removeItem('engagementType')
                              }
                            }}
                            classNamePrefix="react-select"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                    <Grid xs={12} paddingTop={2}>
                      <div style={{ paddingRight: '10px' }}>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder='Search requests by Customer name, Engagement name, Request Id, Briefing Id, Speaker name or Requestor name'
                          value={searchedProperty}
                          fullWidth
                          onChange={search}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon style={{ color: '#5ea9ff' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
              <Grid container paddingTop={1} paddingRight={1}>
                <Grid xs={12}>
                <div style={{ display: 'flex',justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: '#2088ff', height: '40px', fontSize: '12px' }} onClick={clearAll} >Clear All</Button>
                  </div>
                </Grid>
              </Grid>
              <Grid container >
                <Grid xs={9}>
                  <div style={{}}>
                    <Tooltip title="Please consider choosing to show only first 200 records. It makes the search faster." arrow>
                      <Checkbox
                        checked={show200Records}
                        onChange={handleShowToggle}
                        inputProps={{ 'aria-label': 'Show 200 Records' }}
                      />
                    </Tooltip>
                    <span>{show200Records ? 'Displaying first 200 records' : 'Displaying all records'}</span>
                  </div>
                </Grid>
                <Grid xs={3}>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={0.3} >

                {cards?.length > 0 ? cards?.map((item, index) => {
                  const requestStatus = item.fields['Custom.RequestStatus'];
                  const borderLeftColor = requestStatus === 'Speaker Requested' ? '#3860b2' : (requestStatus === 'Speaker Accepted' ? '#00126b' : (requestStatus === 'In Triage' ? '#c3b305' : (requestStatus === 'Speaker Assigned' ? '#47b932' : (requestStatus === 'Action Required' ? '#e3300c' : 'grey'))));
                  return (
                    <Grid item xs={4}>

                      <Container className={classes.container}
                        style={{
                          borderLeft: `2px solid ${borderLeftColor}`
                        }}
                      >

                        <Typography variant="" className={classes.description}>
                          <b>Customer Name:</b> {truncateText(item.fields['Custom.CustomersName'] || '-', 45)} <br />
                          <b>Engagement:</b> {truncateText(item.fields['System.Title'] || '', 40)} <br />
                          <b>Meeting:</b> {item.fields['Custom.MeetingFormat']} - {item.fields['Custom.RequestEngagementType']}<br />
                          <b>Service/Topic:</b> {truncateText(item.fields["Custom.ServiceInterest"] || item.fields['Custom.CustomTopicTitle'] || item.fields['Custom.AITopics'] || "", 30)} <br />
                          <b>Status:</b> {item.fields['Custom.RequestStatus']} <br />
                        </Typography>


                        <div style={{ margin: '6px' }}>
                          <Divider />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <img
                            src={item.fields['Custom.IsVisible'] ? EyeOpenImg : EyeCloseImg}
                            alt=''
                            style={{
                              width: '21px',
                              height: '21px',
                            }}
                          />


                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <Link to={'/edit'}>
                              <img
                                src={editImg}
                                alt=''
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginRight: '10px'
                                }}
                                onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                              />
                            </Link>

                            {item.fields['Custom.RequestStatus'] === 'In Triage' &&
                              <Link to='/triagemail'>
                                <img
                                  src={CopyImg}
                                  alt=''
                                  style={{
                                    width: '21px',
                                    height: '21px',
                                    marginRight: '10px',
                                  }}
                                  onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                                />
                              </Link>
                            }

                            <Link to='/mail'> <img
                              src={mailImg}
                              alt=''
                              style={{
                                width: '21px',
                                height: '21px',
                              }}
                              onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                            /></Link>

                          </div>
                        </div>

                      </Container>

                    </Grid>
                  )
                }) : searchProgress ? <span style={{ marginLeft: '10px' }} >Please wait ...</span> :<span style={{ marginLeft: '10px' }} >No data found matching selection criteria</span>}
              </Grid>
            </Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showProgress}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        }
      </>
    );
  } catch (error) {
    return <ExceptionHandled />;
  }
}