import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
// import logo from './../../assets/Images/Microsoft Icon -Flow 2.avif'
// import logoutImg from './../../assets/Images/logout (2).png';
// import { Avatar, Divider } from '@mui/material';
// import { ArrowBack } from '@mui/icons-material';
import MailImg from './../../assets/Images/Heder/Email1.png';
import TickImg from './../../assets/Images/Heder/Tick.png';
import HomeImg from './../../assets/Images/Heder/Home.png';
import deleteImg from './../../assets/Images/Heder/Delete.png'
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

const drawerWidth = 337;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: '50px',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function MenubarfindSpeaker(props) {
    try {
        return (
            <>
                <CssBaseline />
                <AppBar position="fixed" style={{ backgroundColor: '', marginTop: '53px' }}>
                    <Toolbar>

                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="back"
                            aria-haspopup="false"
                            onClick={() => { window.history.back() }}
                        >
                            <ArrowBack style={{ color: 'white', fontSize: '30px' }} ></ArrowBack>
                        </IconButton>


                        {/* <Link to={'/home'}>
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="home"
                                aria-haspopup="false"
                            >
                                <img
                                    src={HomeImg}
                                    alt='logout'
                                    style={{
                                        width: '28px',
                                        height: '28px',
                                    }}
                                />
                            </IconButton>
                        </Link> */}

                    </Toolbar>
                </AppBar>
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}