import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import BaseConfig from '../../config/config';

const MicrosoftLogin2 = (props) => {
  // eslint-disable-next-line
  const [userName, setUserName] = useState("");
  const [calledSignIn, setCalledSignIn] = useState(false);
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((s) => {
        if (s !== null && s.account != null) {
          const username = s.account.name;
          const emailid = s.account.username;
          const accountId = s.account.localAccountId;
          setUserName(username);
          sessionStorage.setItem("userName", username);
          sessionStorage.setItem("Emailid", emailid);
          sessionStorage.setItem("accountId", accountId);
          const accessToken = s.accessToken; // Access token obtained
          sessionStorage.setItem("userToken", accessToken);
          const config = {
            headers: {
              'content-type': 'application/json',
            }
          };
          axios.get(BaseConfig.apiBaseUrl + 'triage/authorization/' + sessionStorage.getItem('Emailid'), config)
            .then(response => {
              if (response.status === 200) {
                if(response?.data?.data?.fields["Custom.Permissions"]?.toLowerCase()?.includes("triage login")){
                  sessionStorage.setItem("triageLogin","yes")
                  if(response?.data?.data?.fields["Custom.Permissions"]?.toLowerCase()?.includes("triage edit")){
                    sessionStorage.setItem("triageEdit","yes")
                  }
                  navigate('/home');
                }else{
                  sessionStorage.clear();
                  console.error('authorization Data not found')
                  navigate("/unathorized")
                }
              }
              else {
                sessionStorage.clear();
                console.error('authorization Data not found')
                navigate("/unathorized")
              }
            })
            .catch(function (error) {
              sessionStorage.clear();
              console.error(error);
              navigate("/unathorized")
            });
        }
      }).catch((error) => {
        console.log(error);
        console.error("Failed to login into application", error);
      });
    const accounts = instance.getAllAccounts();
    console.log("inprogress status : ", inProgress);
    if ((inProgress === InteractionStatus.None) && (calledSignIn === false) && (!accounts || accounts?.length <= 0)) {
      signIn();
      setCalledSignIn(true)
    }
    // eslint-disable-next-line
  }, [inProgress]);

  function signIn() {
    console.log("number of times sign in called");
    const loginScope = {
      scopes: ["User.Read","openid", "profile", "offline_access","email","User.ReadBasic.All"],
    };
    instance.loginRedirect(loginScope);
  }

  return (
    <div className="App">
      {/* <h1 style={{ fontFamily: "Segoe UI Regular", fontWeight: "600" }}>
        Sign In
      </h1>
      <button
        onClick={signIn}
        style={{
          fontFamily: "Segoe UI Regular",
          fontWeight: "600",
          border: "none",
          padding: "12px 12px 12px 12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Sign In with Microsoft
      </button> */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default MicrosoftLogin2;
