import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Checkbox, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, List, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';
import Reactselect from 'react-select';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { Menu } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from './../../assets/Images/Logo.png'
import logoutImg from './../../assets/Images/logout (2).png';
import sharepointImg from './../../assets/Images/SharePoint_(2019present).png';
import drawerMailImg from './../../assets/Images/Mail_(iOS).png'
import powerbiImg from './../../assets/Images/powerbi.png';
import TechnicalContentImg from './../../assets/Images/technicalApp.png'
import mailImg from './../../assets/Images/mail.png'
import editImg from './../../assets/Images/edit.png';
import filterImg from './../../assets/Images/filter.png'
import findSpeakerImg from './../../assets/Images/search.png'
import { orderBy } from 'lodash';
import { useMsal } from '@azure/msal-react';

import axios from 'axios';
import Navbar from '../components/Navbar';
import BaseConfig from '../../config/config';
import ExceptionHandled from '../UnathorizedPage/exceptionhandled';

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "....";
    }
    return text;
}

const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '40px',
        height: '40px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
};


const statuslist = [
    { value: 'Content Requested', label: 'Content Requested' },
    { value: 'In Triage', label: 'In Triage' },
    { value: 'In Production', label: 'In Production' },
    { value: 'Request Declined', label: 'Request Declined' },
    { value: 'Completed/Published', label: 'Completed/Published' },
];

const contentAssetList = [
    { label: 'AI Show', value: 'AI Show' },
    { label: 'Demo', value: 'Demo' },
    { label: 'AI YouTube Channel Video Submission', value: 'AI YouTube Channel Video Submission' },
    { label: 'Blog Post', value: 'Blog Post' },
    { label: 'Field Briefing', value: 'Field Briefing' },
    { label: 'Technical Consult', value: 'Technical Consult' },
    { label: 'Skilling Session', value: 'Skilling Session' },
    { label: 'Technical Documentation', value: 'Technical Documentation' },
    { label: 'Technical Workshop', value: 'Technical Workshop' },
];

const sortList = [
    { label: 'Created On - Newest to Oldest', value: 'Created On - Newest to Oldest' },
    { label: 'Created On - Oldest to Newest', value: 'Created On - Oldest to Newest' },
    { label: 'Publication Date - Newest to Oldest', value: 'Publication Date - Newest to Oldest' },
    { label: 'Publication Date - Oldest to Newest', value: 'Publication Date - Oldest to Newest' },
];


const itemsBelow = [
    {
        index: '1', text: 'E-Mail', img: <img
            src={drawerMailImg}
            alt=''
            style={{
                width: '21px',
                height: '21px',
            }}
        />,
        url: '/technicalcontentmail'
    },
    {
        index: '1', text: 'AI Insights', img: <img
            src={powerbiImg}
            alt=''
            style={{
                width: '21px',
                height: '21px',
            }}
        />,
        url: 'http://aka.ms/aiinsights'
    },
    {
        index: '2', text: 'AI Requests', img: <img
            src={sharepointImg}
            alt=''
            style={{
                width: '21px',
                height: '21px',
            }}
        />,
        url: 'https://microsoft.sharepoint.com/teams/WWAISpeakerEngagements/Lists/EngagementRequests/'
    },
    {
        index: '3', text: 'myCE', img: <img
            src={logo}
            alt=''
            style={{
                width: '21px',
                height: '21px',
            }}
        />,
        url: 'https://myce.azureedge.net/'
    },
    {
        index: '3', text: 'Speaker Escalation', img: <img
            src={powerbiImg}
            alt=''
            style={{
                width: '21px',
                height: '21px',
            }}
        />,
        url: 'https://msit.powerbi.com/groups/me/apps/df8b8a78-52f4-4df8-b379-c58615fba4f2/reports/7af65c81-067d-4ecc-a881-d3729a970664'
    },
    {
        index: '4', text: 'Triage (Engagement Request)',
        img: <img
            src={TechnicalContentImg}
            alt=''
            style={{
                width: '30px',
                height: '30px',
            }}
        />,
        url: '/home'
    },
    {
        index: '5', text: 'Find Speaker',
        img: <img
            src={findSpeakerImg}
            alt=''
            style={{
                width: '30px',
                height: '30px',
            }}
        />,
        url: '/findSpeaker'
    },
];

const drawerWidth = 290;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: open ? 'wrap' : 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#E9EFF2',
        padding: theme.spacing(1.1),
        textDecoration: 'none',
        borderLeft: 'grey'
    },
    image: {
        width: '100%',
        height: '140',
        backgroundColor: '#ffffff',
        marginBottom: '13',
    },
    description: {
        marginTop: theme.spacing(2.5),
        color: 'black',
        textDecoration: 'none',
        fontSize: '12px',
        fontFamily: 'Segoe UI Regular'
    },
}));

export default function ContentDashboard() {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const { instance, inProgress, accounts } = useMsal();
    const [cards, setCards] = React.useState([]);
    const [status, setStatus] = React.useState('');
    const [contentAssetType, setContentAssetType] = React.useState('');
    const [searchedProperty, setSearchedProperty] = React.useState('');
    const [sortby, setSortBy] = React.useState('Created On - Newest to Oldest');
    const [showProgress, setShowProgress] = React.useState(true);
    const [searchProgress, SetSearchProgress] = React.useState(false)
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [show200Records, setShow200Records] = React.useState(true);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const handleSortSelected = (value) => {
        setSortBy(value);
        if (value === 'Publication Date - Oldest to Newest') {
            const sortOrder = 'asc';
            const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.PublicationDate'])], [sortOrder]);
            setCards(sortedData);
        }
        else if (value === 'Publication Date - Newest to Oldest') {
            const sortOrder = 'desc';
            const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.PublicationDate'])], [sortOrder]);
            setCards(sortedData);
        }
        else if (value === 'Created On - Newest to Oldest') {
            const sortOrder = 'desc';
            const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.CreatedOn'])], [sortOrder]);
            setCards(sortedData);
        }
        else if (value === 'Created On - Oldest to Newest') {
            const sortOrder = 'asc';
            const sortedData = orderBy(cards, [item => new Date(item.fields['Custom.CreatedOn'])], [sortOrder]);
            setCards(sortedData);
        }
    }

    const handleStatusSelected = (value) => {
        setStatus(value);
        getCardsByProperty('status', value);
        sessionStorage.setItem('TCstatus', value)
    }

    const handleTopicSelected = (value) => {
        setContentAssetType(value);
        getCardsByProperty('contentAssetType', value);
        sessionStorage.setItem('contentAssetType', value)
    }

    const clearAll = () => {
        setSearchedProperty('');
        setStatus('');
        setContentAssetType('');
        sessionStorage.removeItem('TCstatus');
        sessionStorage.removeItem('TCproperty');
        sessionStorage.removeItem('contentAssetType');
        getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
    }

    const handleShowToggle = (event) => {
        sessionStorage.setItem('show200Records', event.target.checked)
        getAllCards(event.target.checked)
    }

    const getAllCards = (value) => {
        setShowProgress(true);
        setShow200Records(value);
        const formData = new FormData();
        formData.append("extractLessData", value);
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.post(BaseConfig.apiBaseUrl + 'triage/getContentRequest/' + sessionStorage.getItem('Emailid'), formData, config)
            .then(response => {
                if (response.data.status) {
                    setCards(value ? response.data.data.value : response.data.data)
                    setShowProgress(false);
                }
            })
            .catch(function (error) {
                console.error(error);
                setShowProgress(false);
            });
    }

    const search = (event) => {
        sessionStorage.setItem('TCproperty', event.target.value)
        setSearchedProperty(event.target.value);
        getCardsByProperty('property', event.target.value);
        // const formData = new FormData();
        // formData.append("user_id", sessionStorage.getItem('Emailid'));
        // formData.append("property", event.target.value);
        // const config = {
        //   headers: {
        //     'content-type': 'application/json',
        //   }
        // };
        // axios.post(BaseConfig.apiBaseUrl + 'triage/searchRequest', formData, config)
        //   .then(response => {
        //     if (response.data.status) {
        //       setCards(response.data.data.value)
        //     }
        //   })
        //   .catch(function (error) {
        //     console.error(error);
        //   });
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        // if (sessionStorage.getItem('userName')) {
        //   getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
        // }
        // else {
        //   navigate('/');
        // }
        const loginProcess = async () => {
            var successfulSignIn = false;
            console.log(accounts);
            try {
                if (accounts.length > 0) {
                    const accessTokenRequest = {
                        scopes: ["https://graph.microsoft.com/.default"],
                        account: accounts ? accounts[0] : null,
                    };
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    sessionStorage.setItem("userName", response.account.name);
                    sessionStorage.setItem("Emailid", response.account.username);
                    sessionStorage.setItem("accountId", response.account.localAccountId);
                    sessionStorage.setItem("userToken", response.accessToken);
                    setIsSignedIn(true)
                    successfulSignIn = true
                } else {
                    throw Error("not found any current active account")
                }
            } catch (error) {
                console.log('Error acquiring token silently:', error);
                sessionStorage.clear();
                window.location.replace('/');
            }
            if (successfulSignIn) {
                if (sessionStorage.getItem('TCstatus') || sessionStorage.getItem('TCproperty') || sessionStorage.getItem('contentAssetType')) {
                    getCardsBySessionProperty();
                }
                else {
                    getAllCards(sessionStorage.getItem('show200Records') ? (sessionStorage.getItem('show200Records') === 'true' ? true : false) : true);
                }
            }
        }
        loginProcess();
        // eslint-disable-next-line
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getCardsByProperty = (property, value) => {
        const formData = new FormData();
        let propertySerached = false;
        let propertyValue;
        if ((property === 'property' || searchedProperty !== '')) {
            formData.append("property", property === 'property' ? value : searchedProperty);
            propertySerached = true;
            propertyValue = property === 'property' ? value : searchedProperty;
        }
        if ((property === 'status' || status !== '')) {
            formData.append("status", property === 'status' ? value : status);
        }
        if ((property === 'contentAssetType' || contentAssetType !== '')) {
            formData.append("contentAssetType", property === 'contentAssetType' ? value : contentAssetType);
        }
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.post(BaseConfig.apiBaseUrl + 'triage/searchContentRequest/' + sessionStorage.getItem('Emailid'), formData, config)
            .then(response => {
                if (response.data.status) {
                    if (propertySerached) {
                        if (propertyValue === sessionStorage.getItem('TCproperty')) {
                            setCards(response.data.data.value)
                        }
                    } else {
                        setCards(response.data.data.value)
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
                setCards([]);
            });
    }

    const getCardsBySessionProperty = () => {
        const formData = new FormData();
        if (sessionStorage.getItem('TCproperty')) {
            setSearchedProperty(sessionStorage.getItem('TCproperty'))
            formData.append("property", sessionStorage.getItem('TCproperty'));
        }
        if (sessionStorage.getItem('TCstatus')) {
            setStatus(sessionStorage.getItem('TCstatus'))
            formData.append("status", sessionStorage.getItem('TCstatus'));
        }
        if (sessionStorage.getItem('contentAssetType')) {
            setContentAssetType(sessionStorage.getItem('contentAssetType'))
            formData.append("contentAssetType", sessionStorage.getItem('contentAssetType'));
        }
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.post(BaseConfig.apiBaseUrl + 'triage/searchContentRequest/' + sessionStorage.getItem('Emailid'), formData, config)
            .then(response => {
                if (response.data.status) {
                    setCards(response.data.data.value)
                    setShowProgress(false)
                }
            })
            .catch(function (error) {
                console.error(error);
                setCards([]);
                setShowProgress(false);
            });
    }

    // const items = [
    //   {
    //     index: '1',
    //     text: <TreeView
    //       aria-label="file system navigator"
    //       defaultCollapseIcon={<ExpandMoreIcon />}
    //       defaultExpandIcon={<ChevronRightIcon />}
    //       defaultExpanded={['1']}
    //       sx={{
    //         height: open ? 350 : 30,
    //         flexGrow: 1,
    //         maxWidth: 400,
    //         overflowY: 'auto',
    //         fontFamily: 'Segoe UI Regular'
    //       }}
    //     >
    //       <TreeItem nodeId="1" label={
    //         <Box display="flex" justifyContent="space-between" alignItems="center">
    //           <b>Search by Status</b>
    //           <IconButton
    //             aria-label="Close"
    //             onClick={handleClearStatusClick}
    //             edge="end"
    //           >
    //             <Close />
    //           </IconButton>
    //         </Box>
    //       }>
    //         {statuslist.map((item) => (
    //           <TreeItem
    //             key={item.id}
    //             nodeId={item.id}
    //             label={item.label}
    //             onClick={() => handleStatusSelected(item.label)}
    //           />
    //         ))}
    //       </TreeItem>
    //     </TreeView>,
    //     img: <img
    //       src={open ? blankImg : filterImg}
    //       alt=''
    //       style={{
    //         width: '21px',
    //         height: '21px',
    //       }}
    //     />
    //   },
    //   {
    //     index: '2', text: <TreeView
    //       aria-label="file system navigator"
    //       defaultCollapseIcon={<ExpandMoreIcon />}
    //       defaultExpandIcon={<ChevronRightIcon />}
    //       defaultExpanded={['1']}
    //       sx={{ height: open ? 350 : 30, flexGrow: 1, maxWidth: 400, overflowY: 'auto', fontFamily: 'Segoe UI Regular' }}
    //     >
    //       <TreeItem nodeId="1" label={
    //         <Box display="flex" justifyContent="space-between" alignItems="center">
    //           <b>Search by Service</b>
    //           <IconButton
    //             aria-label="Close"
    //             onClick={handleClearServiceClick}
    //             edge="end"
    //           >
    //             <Close />
    //           </IconButton>
    //         </Box>}>
    //         {sessionlist.map((item) => (
    //           <TreeItem
    //             key={item.id}
    //             nodeId={item.id}
    //             label={item.label}
    //             onClick={() => handleSessionSelected(item.label)}
    //           />
    //         ))}
    //       </TreeItem>
    //     </TreeView>
    //     , img: <img
    //       src={open ? filterImg : blankImg}
    //       alt=''
    //       style={{
    //         width: '21px',
    //         height: '21px',
    //       }}
    //     />
    //   },
    //   {
    //     index: '3', text: <TreeView
    //       aria-label="file system navigator"
    //       defaultCollapseIcon={<ExpandMoreIcon />}
    //       defaultExpandIcon={<ChevronRightIcon />}
    //       defaultExpanded={['1']}
    //       sx={{ height: open ? 230 : 30, flexGrow: 1, maxWidth: 400, overflowY: 'auto', fontFamily: 'Segoe UI Regular' }}
    //     >
    //       <TreeItem nodeId="1" label={
    //         <Box display="flex" justifyContent="space-between" alignItems="center">
    //           <b>Search by Topic</b>
    //           <IconButton
    //             aria-label="Close"
    //             onClick={handleCleartopicClick}
    //             edge="end"
    //           >
    //             <Close />
    //           </IconButton>
    //         </Box>
    //       } >
    //         {topiclist.map((item) => (
    //           <TreeItem
    //             key={item.id}
    //             nodeId={item.id}
    //             label={item.label}
    //             onClick={() => handleTopicSelected(item.label)}
    //           />
    //         ))}
    //       </TreeItem>
    //     </TreeView>, img: <img
    //       src={blankImg}
    //       alt=''
    //       style={{
    //         width: '21px',
    //         height: '21px',
    //       }}
    //     />
    //   },
    // ];

    let initials = null;
    try {
        const userName = sessionStorage.getItem('userName');
        if (userName !== null) {
            // eslint-disable-next-line
            initials = userName?.split(' ')
                .map(word => word.charAt(0))
                .join('');
        }
    } catch (error) {
        // Handle the error, if needed
    }

    try {
        return (
            <>
                {isSignedIn &&
                    <Box sx={{ display: 'flex' }}>
                        <Navbar portalName={"- Technical Content Requests"}></Navbar>
                        <Drawer variant="permanent" open={open}>
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                            <List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                <div>
                                    <List>
                                        <ListItem disablePadding sx={{ display: 'block' }} >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                                onClick={open ? handleDrawerClose : handleDrawerOpen}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Menu style={{ color: 'black' }} />
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary="EngageAI Triage"
                                                    sx={{
                                                        opacity: open ? 1 : 0,

                                                    }}


                                                > </ListItemText>
                                            </ListItemButton>
                                        </ListItem>

                                    </List>

                                    <Divider />

                                    <List >
                                        {itemsBelow.map(({ text, img, url }) => (
                                            <ListItem key={text} disablePadding sx={{ display: 'block' }} >
                                                <MUILink href={url}
                                                    target={(url === '/technicalcontentmail' || url === '/home' || url === '/findSpeaker') ? '_self' : '_blank'}
                                                    rel="noopener noreferrer"
                                                    style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                                    <Tooltip
                                                        title={text}
                                                        disableHoverListener={open}
                                                        placement='right'
                                                        arrow
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent: open ? 'initial' : 'center',
                                                                px: 2.5,
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: open ? 3 : 'auto',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                {img}

                                                            </ListItemIcon>

                                                            <ListItemText
                                                                primary={text}
                                                                sx={{
                                                                    opacity: open ? 1 : 0,
                                                                }}
                                                            ></ListItemText>
                                                            {text === 'Usage & Subscription' && open && (
                                                                <img
                                                                    src={logoutImg}
                                                                    alt={text}
                                                                    style={{
                                                                        width: '21px',
                                                                        height: '21px',
                                                                        position: 'absolute',
                                                                        right: '8px',
                                                                        top: '50%',
                                                                        transform: 'translateY(-50%)',
                                                                    }}
                                                                />
                                                            )}
                                                        </ListItemButton>
                                                    </Tooltip>
                                                </MUILink>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>

                                {/* <div>
            <Divider />
            <List >
              {itemsBelow.map(({ text, img, url }) => (
                <ListItem key={text} disablePadding sx={{ display: 'block' }} >
                  <MUILink href={url} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {img}


                      </ListItemIcon>

                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: open ? 1 : 0,

                        }}


                      ></ListItemText>
                      {text === 'Usage & Subscription' && open && (
                        <img
                          src={logoutImg}
                          alt={text}
                          style={{
                            width: '21px',
                            height: '21px',
                            position: 'absolute',
                            right: '8px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                        />
                      )}
                    </ListItemButton>
                  </MUILink>
                </ListItem>
              ))}
            </List>
          </div> */}
                            </List>
                        </Drawer >
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <DrawerHeader />
                            <div className="new-application-note" style={{ marginBottom: '30px' }}>
                                <span>
                                    Welcome to the new Engage AI Triage application! This is a Beta release. If you have any questions please reach out to{" "}
                                    <a
                                        href="mailto:engageai@microsoft.com"
                                        title="mailto:engageai@microsoft.com"
                                    >
                                        engageai@microsoft.com
                                    </a>
                                </span>
                            </div>

                            {/* <Grid item xs={12} style={{ fontSize: '18px', marginBottom: '20px', fontFamily: 'Segoe UI Regular' }}><b> Welcome Back, {sessionStorage.getItem('userName')?.split(' ')[0]} </b></Grid> */}

                            <Grid container paddingBottom={3} >
                                <Grid xs={1}>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px', marginTop: '10px' }}>
                                        <img
                                            src={filterImg}
                                            alt=''
                                            style={{
                                                width: '21px',
                                                height: '21px',
                                                marginRight: '7px'
                                            }}
                                        />
                                        Filters
                                    </div>
                                </Grid>
                                <Grid xs={3}>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px' }}>
                                        {/* {(status && status!=="") && (<div className="filters-label" style={{marginTop:'-14px',marginBottom:'1px'}}><span>Filter by Status</span></div>)} */}
                                        <FormControl sx={{ flexGrow: 1 }} size="small">
                                            <Reactselect
                                                isClearable
                                                id="demo-select-small"
                                                name="Filter by Status"
                                                placeholder="Filter by Status"
                                                styles={reactSelectCustomStyles}
                                                options={statuslist.map((item) => ({
                                                    value: item.value,
                                                    label: item.label,
                                                }))}
                                                value={status ? {
                                                    value: status,
                                                    label: status,
                                                } : ''}
                                                // styles={customStyles}
                                                onChange={(selectedOptions) => {
                                                    if (selectedOptions) {
                                                        handleStatusSelected(selectedOptions.value)
                                                    } else {
                                                        setStatus('')
                                                        getCardsByProperty('status', '')
                                                        sessionStorage.removeItem('TCstatus')
                                                    }
                                                }
                                                }
                                                classNamePrefix="react-select"
                                            />
                                        </FormControl>
                                        {/* <Button variant="contained" style={{ width: '20px', height: '40px', backgroundColor: 'white', border: '1px solid #2088ff', borderRadius: '0px' }} onClick={handleClearStatusClick} >
                                        <Close style={{ color: '#2088ff' }} />
                                    </Button> */}
                                    </div>
                                </Grid>

                                <Grid xs={3}>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                                        <FormControl sx={{ flexGrow: 1 }} size="small">
                                            {/* <InputLabel id="demo-select-small-label">Filter by Content Asset Type</InputLabel> */}
                                            <Reactselect
                                                isClearable
                                                id="demo-select-small"
                                                name="Filter by Content Asset Type"
                                                placeholder="Filter by Content Asset Type"
                                                styles={reactSelectCustomStyles}
                                                options={contentAssetList.map((item) => ({
                                                    value: item.value,
                                                    label: item.label,
                                                }))}
                                                value={contentAssetType ? {
                                                    value: contentAssetType,
                                                    label: contentAssetType,
                                                } : ''}
                                                // styles={customStyles}
                                                onChange={(selectedOptions) => {
                                                    if (selectedOptions) {
                                                        handleTopicSelected(selectedOptions.value)
                                                    } else {
                                                        setContentAssetType('')
                                                        getCardsByProperty('contentAssetType', '')
                                                        sessionStorage.removeItem('contentAssetType')
                                                    }
                                                }
                                                }
                                                classNamePrefix="react-select"
                                            />
                                        </FormControl>
                                        {/* <Button variant="contained" style={{ width: '20px', height: '40px', backgroundColor: 'white', border: '1px solid #2088ff', borderRadius: '0px' }} onClick={handleCleartopicClick}>
                                        <Close style={{ color: '#2088ff' }} />
                                    </Button> */}
                                    </div>
                                </Grid>

                                <Grid xs={4}>
                                </Grid>
                            </Grid>


                            <Grid container paddingBottom={1}>
                                <Grid xs={9}>
                                    <div style={{ paddingRight: '10px' }}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            placeholder='Search requests by Request Id, Title, Requestor Name, MSFT Presenter Name, Focused Product/Service'
                                            value={searchedProperty}
                                            fullWidth
                                            onChange={search}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon style={{ color: '#5ea9ff' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid xs={2}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl sx={{ flexGrow: 1 }} size="small">
                                            <InputLabel id="demo-select-small-label">Sort by</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                label="Sort by"
                                                value={sortby}
                                                onChange={(event) => { handleSortSelected(event.target.value) }}
                                            >
                                                {sortList.map((item) => (
                                                    <MenuItem value={item.value}> {item.label} </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid xs={1}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: '#2088ff', height: '40px', fontSize: '12px' }} onClick={clearAll} >Clear All</Button>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid xs={9}>
                                    <div style={{}}>
                                        <Tooltip title="Please consider choosing to show only first 200 records. It makes the search faster." arrow>
                                            <Checkbox
                                                checked={show200Records}
                                                onChange={handleShowToggle}
                                                inputProps={{ 'aria-label': 'Show 200 Records' }}
                                            />
                                        </Tooltip>
                                        <span>{show200Records ? 'Displaying first 200 records' : 'Displaying all records'}</span>
                                    </div>
                                </Grid>

                                <Grid xs={3}>


                                </Grid>
                            </Grid>

                            <Grid container spacing={1} marginTop={0.3} >

                                {cards?.length > 0 ? cards?.map((item, index) => {
                                    const requestStatus = item.fields['Custom.TechnicalContentStatus'];
                                    const borderLeftColor = requestStatus === 'Content Requested' ? '#3860b2' : (requestStatus === 'In Triage' ? '#c3b305' : (requestStatus === 'In Production' ? '#47b932' : '#f2f2f2'));
                                    return (
                                        <Grid item xs={4}>

                                            <Container className={classes.container}
                                                style={{
                                                    borderLeft: `2px solid ${borderLeftColor}`
                                                }}
                                            >

                                                <Typography variant="" className={classes.description} >
                                                    <b>Title:</b> {truncateText(item.fields['Custom.ContentTitle'] || '', 40)} <br></br>
                                                    <b>Content Type:</b> {item.fields['Custom.ContentAssetType']}<br></br>
                                                    <b>Focused Product/Service:</b> {truncateText(item.fields['Custom.ProductOrServiceName'] || '', 37)} <br></br>
                                                    <b>Status:</b> {item.fields['Custom.TechnicalContentStatus']} <br></br>
                                                </Typography>

                                                <div style={{ margin: '6px' }}>
                                                    <Divider />
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                    <Link to={'/technicalcontentedit'}>
                                                        <img
                                                            src={editImg}
                                                            alt=''
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginRight: '10px'
                                                            }}
                                                            onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                                                        />
                                                    </Link>
                                                    {/* <Link to={'/technicalcontentmail'}> <img
                                                    src={mailImg}
                                                    alt=''
                                                    style={{
                                                        width: '21px',
                                                        height: '21px',
                                                    }}
                                                    onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                                                /></Link> */}

                                                    <Link to='/technicalcontentmail'> <img
                                                        src={mailImg}
                                                        alt=''
                                                        style={{
                                                            width: '21px',
                                                            height: '21px',
                                                        }}
                                                        onClick={() => { sessionStorage.setItem('requestId', item.id) }}
                                                    />
                                                    </Link>

                                                </div>
                                                {/* </div> */}

                                            </Container>

                                        </Grid>
                                    )
                                }) : searchProgress ? <span style={{ marginLeft: '10px' }} >Please wait ...</span> : <span style={{ marginLeft: '10px' }} >No data found matching selection criteria</span>}
                            </Grid>
                        </Box>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showProgress}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Box >
                }
            </>
        );
    } catch (error) {
        return <ExceptionHandled />;
    }
}